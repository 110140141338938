<template>
	<div>
		<mainHeader />
		<mainNavigator />

		<div class="py-container detailPage">
			<div id="item">
				<div class="crumb-wrap">
					<ul class="sui-breadcrumb">
						<li>
							<a @click="$router.push('/')">首页</a>
						</li>
						<li>商品详情</li>
						<li @click="$router.go(-1)">
							<a>返回上一页</a>
						</li>
					</ul>
				</div>
				<!--product-info-->
				<div class="product-info">
					<div class="fl preview-wrap">
						<goodsImg :list="goods.gallery"></goodsImg>
					</div>
					<div class="fr itemInfo-wrap" id="app">
						<div class="sku-name">
							<h4>{{ goods.name }}</h4>
						</div>
						<div class="summary">
							<div class="summary-wrap1">
								<div class="title">
									<i>价　　格</i>
								</div>
								<div class="price1">
									<i>¥</i>
									<span>{{ checkedSpecPrice || goods.price1 }}</span>
									<!-- <span v-if="goods.counterPrice" style="color: #999;  padding-left: 5%;display: inline-block;text-decoration: line-through;">￥{{ goods.counterPrice }}</span> -->
								</div>
							</div>
						</div>
						<div class="support">
							<div class="summary-wrap">
								<div class="fl title">
									<i>品　　牌</i>
								</div>
								<div class="fl fix-width">
									<em class="t-gray">{{ brand.name || '-' }}</em>
								</div>
							</div>
						</div>
						<div class="support">
							<div class="summary-wrap">
								<div class="fl title">
									<i>数　　量</i>
								</div>
								<div class="fl fix-width">
									<em class="t-gray" v-if="checkedSpecPrice">{{ tmpSpecTextNum }}</em>
									<em class="t-gray" v-else>{{ goodsNumCount }}</em>
								</div>
							</div>
						</div>
						<div class="support" v-if="goods.unit">
							<div class="summary-wrap">
								<div class="fl title">
									<i>单　　位</i>
								</div>
								<div class="fl fix-width">
									<em class="t-gray">{{ goods.unit }}</em>
								</div>
							</div>
						</div>
						<div class="clearfix choose">
							<div id="specification" class="summary-wrap clearfix">
								<dl v-for="(item, index) in specificationList" :key="index">
									<dt>
										<div class="fl title">
											<i>{{ item.name }}</i>
										</div>
									</dt>
									<dd v-for="(vitem, index2) in item.valueList" :key="index2" @click="clickSkuValue" :data-value-id="vitem.id" :data-name="vitem.specification">
										<a :class="'value ' + (vitem.checked ? 'selected' : '')">{{ vitem.value }}</a>
									</dd>
								</dl>
							</div>
							<div class="summary-wrap" style="overflow:visible;">
								<div class="fl title1">
                  <inputCounter min="1" :max="tmpSpecTextNum || 200" :value="number" @change="onNumberChange($event)" mode="tiny" :tip-max="tmpSpecTextNum > 200 ? '购买商品数量不能大于${value}' : '购买商品数量不能大于库存${value}'"></inputCounter>
									<!-- <div class="control-group">
										<div class="controls">
											<input autocomplete="off" type="text" v-model="number" class="itxt itxt1" @blur="numberBlur" />
											<a class="increment plus" @click="addNumber">+</a>
											<a class="increment mins" @click="cutNumber">-</a>
										</div>
									</div> -->
								</div>
								<div class="fl">
									<ul class="btn-choose unstyled">
										<li @click="addCollectOrNot">
											<a class="sui-btn  btn-danger addshopcar">{{ collectImage ? "取消收藏" : "收藏" }}</a>
										</li>
										<li @click="addToCart" v-if="!soldOut">
											<span class="sui-btn  btn-danger addshopcar">加入购物车</span>
										</li>
										<li v-if="soldOut">
											<span class="sui-btn  btn-danger addshopcar emptyGoods">商品已售空</span>
										</li>
										<li @click="addFast" v-else-if="!isSeckill">
											<span class="sui-btn  btn-danger addshopcar">立即购买</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--product-detail-->
				<div class="clearfix product-detail">
					<div class="fl aside">
						<span class="aside-title">推荐</span>
						<div class="tab-content tab-wraped">
							<div id="index" class="tab-pane active">
								<ul class="goods-list unstyled">
									<li v-for="(item, index) in relatedGoods" :key="index" @click="jumpGoods(item)">
										<img :src="item.picUrl" />
										<p class="name jhx_f7">{{ item.name }}</p>
										<p class="price jhx_f8">￥{{ item.retailPrice }}</p>
									</li>
								</ul>
							</div>
							<div id="profile" class="tab-pane">
								<p>推荐品牌</p>
							</div>
						</div>
					</div>
					<div class="fr detail">
						<div class="tab-main intro">
							<ul class="sui-nav nav-tabs tab-wraped">
								<li class="active">
									<a data-toggle="tab">
										<span>商品介绍</span>
									</a>
								</li>
							</ul>
							<div class="clearfix"></div>
							<div class="tab-content tab-wraped">
								<ul class="zc-attribute" v-if="attribute && attribute.length">
									<li v-for="(item, index) in attribute" :key="index">
										<span>{{ item.attribute }}：</span>
										<span>{{ item.value }}</span>
									</li>
								</ul>
								<div v-html="article_goodsDetail" class="k-detail"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>

		<mainFooter />
	</div>
</template>

<script>
import "@/assets/css/pages-item.css";
import "@/assets/css/pages-zoom.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import inputCounter from "@/pc/components/inputCounter";
import goodsImg from "@/pc/components/goodsImg";

export default {
	name: "productDetail",
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		mainNavigator: mainNavigator,
		inputCounter: inputCounter,
		goodsImg
	},
	data()
	{
		return {
			id: 0,
			goods: {},
			groupon: [],
			//该商品支持的团购规格
			grouponLink: {},
			//参与的团购
			attribute: [],
			issueList: [],
			comment: [],
			brand: {},
			specificationList: [],
			productList: [],
			relatedGoods: [],
			cartGoodsCount: 0,
			userHasCollect: 0,
			number: 1,
			checkedSpecText: "规格数量选择",
			tmpSpecText: "请选择规格数量",
			tmpSpecText: "",
			tmpSpecTextNum: 0,
			checkedSpecPrice: 0,
			openAttr: false,
			openShare: false,
			noCollectImage: false,
			hasCollectImage: true,
			collectImage: false,
			shareImage: "",
			isAgent: false,
			//分享提示弹出
			isGroupon: false,
			//标识是否是一个参团购买
			soldOut: false,
			canWrite: false,
			//用户是否获取了保存相册的权限
			isSeckill: false,
			// 默认非秒杀商品
			seckillId: 0,
			seckillGoodsVo: {},
			countDownHour: "00",
			countDownMinute: "00",
			countDownSecond: "00",
			seckillDoing: false, // 是否正在进行的秒杀
			article_goodsDetail: "",
			soldout: false,
			checkSpecificationUrl: "",
			goodsNumCount: 0
		};
	},
	computed: {
		user()
		{
			return this.$store.getters.user;
		},
		isLogin()
		{
			return this.$store.getters.isLogin;
		}
	},
	watch: {},
	created() { },
	mounted()
	{
		window.scrollTo(0, 0);
		if (this.$route.params && this.$route.params.id)
		{
			this.id = this.$route.params.id;
			this.GoodsDetail();
		}
	},
	methods: {
		GoodsDetail()
		{
			let that = this;
			this.$api.base
				.GoodsDetail({
					id: this.id
				})
				.then((res) =>
				{
					if (!res) return;
					
 					let _specificationList = res.specificationList; // 如果仅仅存在一种货品，那么商品页面初始化时默认checked

					if (!_specificationList.length)
					{
						that.soldOut = true;
					}

					if (_specificationList.length == 1)
					{
						if (_specificationList[ 0 ].valueList.length == 1)
						{
							_specificationList[ 0 ].valueList[ 0 ].checked = true; // 如果仅仅存在一种货品，那么商品价格应该和货品价格一致
							// 这里检测一下

							let _productPrice = res.productList[ 0 ].price;
							let _goodsPrice = res.info.retailPrice;

							if (_productPrice != _goodsPrice)
							{
								console.error("商品数量价格和货品不一致");
							}

							that.checkedSpecText = _specificationList[ 0 ].valueList[ 0 ].value;
							that.tmpSpecText = _specificationList[ 0 ].valueList[ 0 ].value;
							that.tmpSpecTextNum = res.productList[ 0 ].number;
						}
					}

					if (!that.tmpSpecTextNum)
					{
						that.soldOut = true;
					}

					let aaa = res.productList;
					let bbb = aaa.sort((a, b) =>
					{
						return a.price - b.price;
					});

					aaa.forEach((v1) =>
					{
						that.goodsNumCount = that.goodsNumCount + v1.number;
					});

					let checkedSpecPrice = 0;

					let goodsTemp = res.info;

					if (bbb && bbb.length)
					{
						checkedSpecPrice = bbb[ 0 ].price || 0; // res.info.price // retailPrice
						goodsTemp.counterPrice = bbb[ 0 ].counterPrice || 0;
						goodsTemp.price1 = bbb[ 0 ].price || 0;
					}

					if (that.isSeckill)
					{
						checkedSpecPrice = res.seckillGoodsVo.seckillPrice;
					}

					that.goods = res.info;
					that.attribute = res.attribute;
					that.issueList = res.issue;
					that.comment = res.comment;
					that.brand = res.brand;
					that.specificationList = res.specificationList;
					that.productList = res.productList;
					that.userHasCollect = res.userHasCollect;
					that.shareImage = res.shareImage == null ? "" : res.shareImage;
					that.checkedSpecPrice = checkedSpecPrice;
					that.groupon = res.groupon;
					that.seckillGoodsVo = res.seckillGoodsVo;
					// 如果是秒杀商品

					if (res.seckillGoodsVo)
					{
						wxh.time2(res.seckillGoodsVo.stop, that);
					} //如果是通过分享的团购参加团购，则团购项目应该与分享的一致并且不可更改

					if (that.isGroupon)
					{
						let groupons = that.groupon;

						for (var i = 0; i < groupons.length; i++)
						{
							if (groupons[ i ].id != that.grouponLink.rulesId)
							{
								groupons.splice(i, 1);
							}
						}

						groupons[ 0 ].checked = true; //重设团购规格

						that.groupon = groupons;
					}

					if (res.userHasCollect == 1)
					{
						that.collectImage = that.hasCollectImage;
					} else
					{
						that.collectImage = that.noCollectImage;
					}

					//WxParse.wxParse('goodsDetail', 'html', res.info.detail, that)
					that.article_goodsDetail = res.info.detail; // that.escape2Html(res.info.detail); //获取推荐商品

					this.getGoodsRelated();

					this.$api.base.GoodsCount().then((res) =>
					{
						that.cartGoodsCount = res;
					});
				})
				.catch(() =>
				{
					uni.showToast({
						title: "商品已失效"
					});
					setTimeout(() =>
					{
						this.$router.replace("/");
					}, 2000);
				});
		},
		shareFriendOrCircle: function ()
		{
			let that = this;
			util
				.request(
					api.CreateShareImg,
					{
						shareObjId: that.id,
						type: 1 //商品类型的海报图
					},
					"POST"
				)
				.then(function (res)
				{
					if (res.errno === 0)
					{
						that.setData({
							shareImage: res.data.shareUrl
						});
					}
				});

			if (this.openShare === false)
			{
				this.setData({
					openShare: !this.openShare
				});
			} else
			{
				return false;
			}
		},
		handleSetting: function (e)
		{
			var that = this; // console.log(e)

			if (!e.detail.authSetting[ "scope.writePhotosAlbum" ])
			{
				uni.showModal({
					title: "警告",
					content: "不授权无法保存",
					showCancel: false
				});
				that.setData({
					canWrite: false
				});
			} else
			{
				uni.showToast({
					title: "保存成功"
				});
				that.setData({
					canWrite: true
				});
			}
		},
		// 保存分享图
		saveShare: function ()
		{
			let that = this;
			uni.downloadFile({
				url: that.shareImage,
				success: function (res)
				{
					console.log(res);
					uni.saveImageToPhotosAlbum({
						filePath: res.tempFilePath,
						success: function (res)
						{
							uni.showModal({
								title: "存图成功",
								content: "图片成功保存到相册了，可以分享到朋友圈了",
								showCancel: false,
								confirmText: "好的",
								confirmColor: "#a78845",
								success: function (res)
								{
									if (res.confirm)
									{
										console.log("用户点击确定");
									}
								}
							});
						},
						fail: function (res)
						{
							console.log("fail");
						}
					});
				},
				fail: function ()
				{
					console.log("fail");
				}
			});
		},
		//从分享的团购进入
		getGrouponInfo: function (grouponId)
		{
			let that = this;
			util
				.request(api.GroupOnJoin, {
					grouponId: grouponId
				})
				.then(function (res)
				{
					if (res.errno === 0)
					{
						that.setData({
							grouponLink: res.data.groupon,
							id: res.data.goods.id
						}); //获取商品详情

						that.getGoodsInfo();
					}
				});
		},
		// 获取推荐商品
		getGoodsRelated: function ()
		{
			let that = this;
			this.$api.base
				.GoodsRelated({
					id: that.id
				})
				.then(function (res)
				{
					that.relatedGoods = res.goodsList;
				});
		},
		// 团购选择
		clickGroupon: function (event)
		{
			let that = this; //参与团购，不可更改选择

			if (that.isGroupon)
			{
				return;
			}

			let specValueId = event.currentTarget.dataset.valueId;
			let _grouponList = this.groupon;

			for (let i = 0; i < _grouponList.length; i++)
			{
				if (_grouponList[ i ].id == specValueId)
				{
					if (_grouponList[ i ].checked)
					{
						_grouponList[ i ].checked = false;
					} else
					{
						_grouponList[ i ].checked = true;
					}
				} else
				{
					_grouponList[ i ].checked = false;
				}
			}

			that.setData({
				groupon: _grouponList
			});
		},
		// 规格选择
		clickSkuValue: function (event)
		{
			this.number = 1;
			let that = this;
			let specName = event.currentTarget.dataset.name;
			let specValueId = event.currentTarget.dataset.valueId; //判断是否可以点击
			//TODO 性能优化，可在wx:for中添加index，可以直接获取点击的属性名和属性值，不用循环

			let _specificationList = this.specificationList;

			for (let i = 0; i < _specificationList.length; i++)
			{
				if (_specificationList[ i ].name === specName)
				{
					for (let j = 0; j < _specificationList[ i ].valueList.length; j++)
					{
						if (_specificationList[ i ].valueList[ j ].id == specValueId)
						{
							//如果已经选中，则反选
							if (_specificationList[ i ].valueList[ j ].checked)
							{
								_specificationList[ i ].valueList[ j ].checked = false;
							} else
							{
								_specificationList[ i ].valueList[ j ].checked = true; //选择了，则判断当前是否有图片，且图片内容不能为空，不能为默认lazy图片，则替换

								that.setSpecificationUrl(_specificationList[ i ].valueList[ j ].picUrl);
							}
						} else
						{
							_specificationList[ i ].valueList[ j ].checked = false;
						}
					}
				}
			}

			this.setData({
				specificationList: _specificationList
			}); //重新计算spec改变后的信息

			this.changeSpecInfo(); //重新计算哪些值不可以点击
		},
		//获取选中的团购信息
		getCheckedGrouponValue: function ()
		{
			let checkedValues = {};
			let _grouponList = this.groupon;

			for (let i = 0; i < _grouponList.length; i++)
			{
				if (_grouponList[ i ].checked)
				{
					checkedValues = _grouponList[ i ];
				}
			}

			return checkedValues;
		},
		setSpecificationUrl: function (picUrl)
		{
			if (picUrl && picUrl.length > 1 && picUrl.indexOf("lazyload.png") == -1)
				this.setData({
					checkSpecificationUrl: picUrl
				});
		},
		//获取选中的规格信息
		getCheckedSpecValue: function ()
		{
			let checkedValues = [];
			let _specificationList = this.specificationList;

			for (let i = 0; i < _specificationList.length; i++)
			{
				let _checkedObj = {
					name: _specificationList[ i ].name,
					valueId: 0,
					valueText: ""
				};

				for (let j = 0; j < _specificationList[ i ].valueList.length; j++)
				{
					if (_specificationList[ i ].valueList[ j ].checked)
					{
						_checkedObj.valueId = _specificationList[ i ].valueList[ j ].id;
						_checkedObj.valueText = _specificationList[ i ].valueList[ j ].value;
					}
				}

				checkedValues.push(_checkedObj);
			}

			return checkedValues;
		},
		//判断规格是否选择完整
		isCheckedAllSpec: function ()
		{
			return !this.getCheckedSpecValue().some(function (v)
			{
				if (v.valueId == 0)
				{
					return true;
				}
			});
		},
		getCheckedSpecKey: function ()
		{
			let checkedValue = this.getCheckedSpecValue().map(function (v)
			{
				return v.valueText;
			});
			return checkedValue;
		},
		// 规格改变时，重新计算价格及显示信息
		changeSpecInfo: function ()
		{
			let checkedNameValue = this.getCheckedSpecValue(); //设置选择的信息

			let checkedValue = checkedNameValue
				.filter(function (v)
				{
					if (v.valueId != 0)
					{
						return true;
					} else
					{
						return false;
					}
				})
				.map(function (v)
				{
					return v.valueText;
				});

			if (checkedValue.length > 0)
			{
				this.setData({
					tmpSpecText: checkedValue.join("　")
				});
			} else
			{
				this.setData({
					tmpSpecText: "请选择规格数量"
				});
			}

			if (this.isCheckedAllSpec())
			{
				this.setData({
					checkedSpecText: this.tmpSpecText
				}); // 规格所对应的货品选择以后

				let checkedProductArray = this.getCheckedProductItem(this.getCheckedSpecKey());

				if (!checkedProductArray || checkedProductArray.length <= 0)
				{
					this.setData({
						soldOut: true
					});
					console.error("规格所对应货品不存在");
					return;
				}

				let checkedProduct = checkedProductArray[ 0 ];
				this.tmpSpecTextNum = checkedProduct.number;
				let checkedPrice = checkedProduct.price;
				let soldOut = checkedProduct.number <= 0;

				if (this.isSeckill)
				{
					checkedPrice = checkedProduct.seckillPrice;
					soldOut = checkedProduct.seckillNumber <= 0;
				} // 如果选择库存为0 的货品，则价格显示商品销售价格

				if (soldOut)
				{
					// checkedPrice = this.goods.retailPrice
				}

				this.setData({
					checkedSpecPrice: checkedPrice,
					soldOut: soldOut
				});
			} else
			{
				this.setData({
					checkedSpecText: "规格数量选择",
					checkedSpecPrice: this.goods.price // retailPrice,
					// soldOut: false
				});
			}
		},
		// 获取选中的产品（根据规格）
		getCheckedProductItem: function (key)
		{
			return this.productList.filter(function (v)
			{
				// console.log(key.toString() + '--' + v.specifications.toString())

				if (v.specifications.toString() == key.toString())
				{
					return true;
				} else
				{
					return false;
				}
			});
		},
		//添加或是取消收藏
		addCollectOrNot: function ()
		{
			let that = this;
			this.$api.base
				.CollectAddOrDelete({
					type: 0,
					valueId: this.id
				})
				.then(function (res)
				{
					let _res = res;

					if (_res.type == "add")
					{
						that.setData({
							collectImage: that.hasCollectImage
						});
					} else
					{
						that.setData({
							collectImage: that.noCollectImage
						});
					}
				});
		},
		//立即购买（先自动加入购物车）
		addFast: function ()
		{
			let that = this;
			this.openAttr = true;
			if (this.openAttr == false)
			{
				//打开规格选择窗口
				this.setData({
					openAttr: !this.openAttr
				});
			} else
			{
				//提示选择完整规格
				if (!this.isCheckedAllSpec())
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "请选择完整规格"
					});
					return false;
				} //根据选中的规格，判断是否有对应的sku信息

				let checkedProductArray = this.getCheckedProductItem(this.getCheckedSpecKey());

				if (!checkedProductArray || checkedProductArray.length <= 0)
				{
					//找不到对应的product信息，提示没有库存
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				}

				let checkedProduct = checkedProductArray[ 0 ]; //验证库存

				let soldOut = checkedProduct.number <= 0;

				if (that.isSeckill)
				{
					soldOut = checkedProduct.seckillNumber <= 0;
				}

				if (soldOut)
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				} //验证团购是否有效

				let checkedGroupon = this.getCheckedGrouponValue(); //立即购买

				this.$api.base
					.CartFastAdd({
						goodsId: this.goods.id,
						number: this.number,
						productId: checkedProduct.id
					})
					.then(function (res)
					{
						// 如果storage中设置了cartId，则是立即购买，否则是购物车购买
						try
						{
							uni.setStorageSync("cartId", res);
							uni.setStorageSync("grouponRulesId", checkedGroupon.id);
							uni.setStorageSync("grouponLinkId", that.grouponLink.id);
							uni.setStorageSync("seckilled", 0);
							uni.navigateTo({
								url: "/order/confirm"
							});
						} catch (e) { }
					});
			}
		},
		doSeckill: function ()
		{
			let that = this;

			if (this.openAttr == false)
			{
				//打开规格选择窗口
				this.setData({
					openAttr: !this.openAttr
				});
			} else
			{
				//提示选择完整规格
				if (!this.isCheckedAllSpec())
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "请选择完整规格"
					});
					return false;
				} //根据选中的规格，判断是否有对应的sku信息

				let checkedProductArray = this.getCheckedProductItem(this.getCheckedSpecKey());

				if (!checkedProductArray || checkedProductArray.length <= 0)
				{
					//找不到对应的product信息，提示没有库存
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				}

				let checkedProduct = checkedProductArray[ 0 ]; //验证库存

				let soldOut = checkedProduct.number <= 0;

				if (that.isSeckill)
				{
					soldOut = checkedProduct.seckillNumber <= 0;
				}

				if (soldOut)
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				} // 对接秒杀后台接口

				util
					.request(
						api.DoSeckill,
						{
							seckillGoodsId: that.seckillId,
							productId: checkedProduct.id,
							number: that.number
						},
						"GET"
					)
					.then((res) =>
					{
						let status = res.data.status;
						let seckillMsg = res.data.seckillMsg;

						if (status == 2)
						{
							// 秒杀成功
							uni.setStorageSync("cartId", "0");
							uni.setStorageSync("seckilled", 1);
							uni.setStorageSync("seckillGoodsId", that.seckillId);
							uni.navigateTo({
								url: "/pages/checkout/checkout"
							});
						} else
						{
							uni.showToast({
								image: "/static/static/images/icon_error.png",
								title: seckillMsg
							});
						}
					});
			}
		},
		subscribeMsg: function (e)
		{
			let that = this; // 如果是已经提醒的则不需要再执行后续逻辑

			if (that.seckillGoodsVo.tiped == 1)
			{
				console.log("已提醒，请勿重复点击！");
				return;
			}

			let templateId = api.SeckillSubTemplateId;
			uni.requestSubscribeMessage({
				// 相当于询问用户愿不愿意接收这个模板的订阅消息
				tmplIds: [ templateId ],

				success(res)
				{
					if (res[ templateId ] == "accept")
					{
						that.subscribeSeckill();
					}
				},

				complete(res)
				{
					console.log(res);
				}
			});
		},
		subscribeSeckill: function ()
		{
			let that = this;
			let seckillGoodsId = that.seckillId;
			util
				.request(api.SubscribeSeckill, {
					id: seckillGoodsId,
					status: 2
				})
				.then(function (res)
				{
					if (res.errno === 0)
					{
						let seckillGoodsVo = that.seckillGoodsVo;
						seckillGoodsVo.tiped = 1; // 标记已提醒

						that.setData({
							seckillGoodsVo: seckillGoodsVo
						});
					}
				});
		},
		//添加到购物车
		addToCart: function ()
		{
			let that = this;
			this.openAttr = true;
			if (this.openAttr == false)
			{
				//打开规格选择窗口
				this.setData({
					openAttr: !this.openAttr
				});
			} else
			{
				//提示选择完整规格
				if (!this.isCheckedAllSpec())
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "请选择完整规格"
					});
					return false;
				} //根据选中的规格，判断是否有对应的sku信息

				let checkedProductArray = this.getCheckedProductItem(this.getCheckedSpecKey());

				if (!checkedProductArray || checkedProductArray.length <= 0)
				{
					//找不到对应的product信息，提示没有库存
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				}

				let checkedProduct = checkedProductArray[ 0 ]; //验证库存

				let soldOut = checkedProduct.number <= 0;

				if (that.isSeckill)
				{
					soldOut = checkedProduct.seckillNumber <= 0;
				}

				if (soldOut)
				{
					uni.showToast({
						image: "/static/static/images/icon_error.png",
						title: "没有库存"
					});
					return false;
				} //添加到购物车

				this.$api.base
					.CartAdd({
						goodsId: this.goods.id,
						number: this.number,
						productId: checkedProduct.id
					})
					.then(function (res)
					{
						let _res = res;

						uni.showToast({
							title: "添加成功"
						});
						that.setData({
							openAttr: !that.openAttr,
							cartGoodsCount: _res
						});

						if (that.userHasCollect == 1)
						{
							that.setData({
								collectImage: that.hasCollectImage
							});
						} else
						{
							that.setData({
								collectImage: that.noCollectImage
							});
						}

						// uni.navigateTo({
						//   url: "/user/cart"
						// });
					})
					.catch((err) =>
					{
						/* uni.showToast({
							title: err.message
						}); */
					});
			}
		},
		onNumberChange(event)
		{
			this.number = event;
		},
		numberBlur()
		{
			if (this.tmpSpecTextNum)
			{
				if (this.number > this.tmpSpecTextNum)
				{
					this.number = this.tmpSpecTextNum;
				}
			} else
			{
				this.number = 1;
			}
		},
		cutNumber: function ()
		{
			this.setData({
				number: this.number - 1 > 1 ? this.number - 1 : 1
			});
		},
		addNumber: function ()
		{
			if (this.tmpSpecTextNum)
			{
				if (this.number < this.tmpSpecTextNum)
				{
					this.setData({
						number: this.number + 1
					});
				}
			} else
			{
				uni.showToast({
					image: "/static/static/images/icon_error.png",
					title: "没有库存"
				});
			}
		},
		switchAttrPop: function ()
		{
			if (this.openAttr == false)
			{
				this.setData({
					openAttr: !this.openAttr
				});
			}
		},
		closeAttr: function ()
		{
			this.setData({
				openAttr: false
			});
		},
		closeShare: function ()
		{
			this.setData({
				openShare: false
			});
		},
		openCartPage: function ()
		{
			uni.switchTab({
				url: "/pages/cart/cart"
			});
		},
		//根据已选的值，计算其它值的状态
		setSpecValueStatus: function () { },
		onColse: function ()
		{
			this.setData({
				isAgent: false
			});
		},
		jumpGoods(i)
		{
			let str = "/product/detail/";
			// let str = "/product/catalog/";
			if (i.goods)
			{
				str += i.goods.id;
			} else
			{
				str += i.id;
			}
			this.$router.push(str);
			window.scrollTo(0, 0);
			location.reload();
		}
	}
};
</script>

<style lang="scss" scoped>
.detailPage {
	.title {
		padding: 0;
		margin-top: 0;
		color: #333;
	}
	.title1 {
		padding-right: 20px;
	}
	.controls {
		width: 48px;
		position: relative;
		user-select: none;
		a {
			padding: 0;
		}
	}
	::v-deep .k-detail {
		font-size: 0;
		img {
			width: 100%;
			max-width: 100%;
			padding: 0;
			margin: 0;
		}
		img:hover {
			opacity: 1;
		}
	}
	.goods-list {
		img {
			width: 188px;
			height: 188px;
		}
	}
	#index {
		height: 100%;
	}
}
.tab-content.tab-wraped {
	background: #fff;
}
.summary-wrap1 {
	padding: 10px 0;
	display: flex;
	align-items: center;
	.title {
		font-size: 14px;
	}
	.price1 {
		font-size: 20px;
		color: #f60;
	}
}
.emptyGoods {
	background: #999;
	color: #fff;
	border-color: #999;
}
.itxt1 {
	outline: none;
	border: none;
}

.aside-title {
	display: block;
	background-color: #F4F4F4;
	border: 1px solid #ddd;
	padding: 10px;
	font-size: 10.5pt;
}

.zc-attribute {
	padding: 20px;
	border-bottom: solid 1px #EEE;
	margin-bottom: 20px;
	font-size: 10.5pt;
	list-style: none;
	color: #666;
}
.zc-attribute li {
	margin-bottom: 5px;
}
</style>
