/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-08-24 17:49:26
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\common\api\user.js
 */

import domain from "./domain";
import { get, post } from "./axios";

const base = {
  imserver: `/imserver/${localStorage.token}`,
  getArticles(data, bag) {
    //公告
    return get(`${domain.pc}/home/getArticles`, data, null, null, bag);
  },
  regCaptcha(data, bag) {
    //发送短信
    return post(`${domain.pc}/auth/regCaptcha`, data, null, null, bag);
  },
  AuthorizedLogin(data, bag) {
    //微信授权登录并且用户信息以及关注状态
    return post(`${domain.pc}/mobileUser/authorizedLogin`, data, null, null, bag);
  },
  IndexUrl(data, bag) {
    //首页数据接口
    return get(`${domain.pc}/home/index`, data, null, null, bag);
  },
  HotGoods(data, bag) {
    //首页数据接口
    return get(`${domain.pc}/home/getHotGoods`, data, null, null, bag);
  },
  BankLogin(data, bag) {
    //微信行方接口登录
    return get(`${domain.pc}/auth/bank_login`, data, null, null, bag);
  },
  ICBCQueryPoint(data, bag) {
    //
    return get(`${domain.pc}/icbc/queryPoint`, data, null, null, bag);
  },
  ICBCSendMsg(data, bag) {
    //
    return post(`${domain.pc}/icbc/sendMsg`, data, null, null, bag);
  },
  ICBCPayOrder(data, bag) {
    //
    return post(`${domain.pc}/icbc/payOrder`, data, null, null, bag);
  },
  GetCoupon(data, bag) {
    //根据活动id领取满减券
    return get(`${domain.pc}/auth/getCoupon`, data, null, null, bag);
  },
  GetMyCoupon(data, bag) {
    //获取我的优惠券
    return get(`${domain.pc}/auth/getMyCoupon`, data, null, null, bag);
  },
  GetBankAddress(data, bag) {
    //根据活动topicId获取地址
    return get(`${domain.pc}/bankAddress/list`, data, null, null, bag);
  },
  ArticleList(data, bag) {
    //隐私或者协议详情
    return get(`${domain.pc}/article/list`, data, null, null, bag);
  },
  CatalogList(data, bag) {
    //首页数据接口
    return get(`${domain.pc}/catalog/index`, data, null, null, bag);
  },
  CatalogDatas(data, bag) {
    // 三级分类
    return get(`${domain.pc}/catalog/datas`, data, null, null, bag);
  },
  CatalogCurrent(data, bag) {
    //分类目录全部分类数据接口
    return get(`${domain.pc}/catalog/current`, data, null, null, bag);
  },
  AuthLoginByWeixin(data, bag) {
    //
    return get(`${domain.pc}/auth/login_by_weixin`, data, null, null, bag);
  },
  AuthLoginByH5WX(data, bag) {
    //
    return post(`${domain.pc}/auth/h5_login`, data, null, null, bag);
  },
  AuthLoginByJsapi(data, bag) {
    //
    return post(`${domain.pc}/auth/jsapi`, data, null, null, bag);
  },
  CommentLists(data, bag) {
    //
    return get(`${domain.pc}/comment/lists`, data, null, null, bag);
  },
  AuthLoginByAccount(data, bag) {
    //微信登录
    return post(`${domain.pc}/auth/login`, data, null, null, bag);
  },
  AuthAccountUpdate(data, bag) {
    //微信登录
    return post(`${domain.pc}/auth/updateUser`, data, null, null, bag);
  },
  AuthLogout(data, bag) {
    //账号登出
    return post(`${domain.pc}/auth/logout`, data, null, null, bag);
  },
  AuthRegister(data, bag) {
    //账号注册
    return post(`${domain.pc}/auth/register`, data, null, null, bag);
  },
  AuthReset(data, bag) {
    //账号密码重置
    return post(`${domain.pc}/auth/reset`, data, null, null, bag);
  },
  AuthRegisterCaptcha(data, bag) {
    //验证码
    return post(`${domain.pc}/auth/regCaptcha`, data, null, null, bag);
  },
  AuthBindPhone(data, bag) {
    //绑定微信手机号
    return post(`${domain.pc}/auth/bindPhone`, data, null, null, bag);
  },
  CheckLogin(data, bag) {
    //验证码
    return get(`${domain.pc}/auth/checkLogin`, data, null, null, bag);
  },
  GoodsCount(data, bag) {
    //统计商品总数
    return get(`${domain.pc}/goods/count`, data, null, null, bag);
  },
  GoodsList(data, bag) {
    //获得商品列表
    return get(`${domain.pc}/goods/list`, data, null, null, bag);
  },
  GoodsCategory(data, bag) {
    //获得分类数据
    return get(`${domain.pc}/goods/category`, data, null, null, bag);
  },
  GoodsDetail(data, bag) {
    //获得商品的详情
    return get(`${domain.pc}/goods/detail`, data, null, null, bag);
  },
  GoodsRelated(data, bag) {
    //商品详情页的关联商品（大家都在看）
    return get(`${domain.pc}/goods/related`, data, null, null, bag);
  },
  CreateShareImg(data, bag) {
    //创建分享海报
    return post(`${domain.pc}/agency/createShareImg`, data, null, null, bag);
  },
  BrandList(data, bag) {
    //品牌列表
    return get(`${domain.pc}/brand/list`, data, null, null, bag);
  },
  BrandDetail(data, bag) {
    //品牌详情
    return get(`${domain.pc}/brand/detail`, data, null, null, bag);
  },
  CartList(data, bag) {
    //获取购物车的数据
    return get(`${domain.pc}/cart/index`, data, null, null, bag);
  },
  CartAdd(data, bag) {
    //添加商品到购物车
    return post(`${domain.pc}/cart/add`, data, null, null, bag);
  },
  CartFastAdd(data, bag) {
    //立即购买商品
    return post(`${domain.pc}/cart/fastadd`, data, null, null, bag);
  },
  CartUpdate(data, bag) {
    //更新购物车的商品
    return post(`${domain.pc}/cart/update`, data, null, null, bag);
  },
  CartDelete(data, bag) {
    //删除购物车的商品
    return post(`${domain.pc}/cart/delete`, data, null, null, bag);
  },
  CartChecked(data, bag) {
    //选择或取消选择商品
    return post(`${domain.pc}/cart/checked`, data, null, null, bag);
  },
  CartGoodsCount(data, bag) {
    //获取购物车商品件数
    return get(`${domain.pc}/cart/goodscount`, data, null, null, bag);
  },
  CartCheckout(data, bag) {
    //下单前信息确认
    return get(`${domain.pc}/cart/checkout`, data, null, null, bag);
  },
  CollectList(data, bag) {
    //收藏列表
    return get(`${domain.pc}/collect/list`, data, null, null, bag);
  },
  CollectAddOrDelete(data, bag) {
    //添加或取消收藏
    return post(`${domain.pc}/collect/addordelete`, data, null, null, bag);
  },
  CollectAdd(data, bag) {
    //添加或取消收藏
    return post(`${domain.pc}/collect/add`, data, null, null, bag);
  },
  CollectDeleteBat(data, bag) {
    //添加或取消收藏
    return post(`${domain.pc}/collect/deleteCollects`, data, null, null, bag);
  },
  CommentList(data, bag) {
    //评论列表
    return get(`${domain.pc}/comment/list`, data, null, null, bag);
  },
  CommentCount(data, bag) {
    //评论总数
    return post(`${domain.pc}/comment/count`, data, null, null, bag);
  },
  CommentPost(data, bag) {
    //发表评论
    return post(`${domain.pc}/comment/post`, data, null, null, bag);
  },
  TopicList(data, bag) {
    //专题列表
    return get(`${domain.pc}/topic/list`, data, null, null, bag);
  },
  TopicDetail(data, bag) {
    //专题详情
    return get(`${domain.pc}/topic/detail`, data, null, null, bag);
  },
  TopicRelated(data, bag) {
    //相关专题
    return get(`${domain.pc}/topic/related`, data, null, null, bag);
  },
  SearchIndex(data, bag) {
    //搜索关键字
    return get(`${domain.pc}/search/index`, data, null, null, bag);
  },
  SearchResult(data, bag) {
    //搜索结果
    return get(`${domain.pc}/search/result`, data, null, null, bag);
  },
  SearchHelper(data, bag) {
    //搜索帮助
    return get(`${domain.pc}/search/helper`, data, null, null, bag);
  },
  SearchClearHistory(data, bag) {
    //搜索历史清楚
    return post(`${domain.pc}/search/clearhistory`, data, null, null, bag);
  },
  AddressList(data, bag) {
    //收货地址列表
    return get(`${domain.pc}/address/list`, data, null, null, bag);
  },
  AddressDetail(data, bag) {
    //收货地址详情
    return get(`${domain.pc}/address/detail`, data, null, null, bag);
  },
  AddressSave(data, bag) {
    //保存收货地址
    return post(`${domain.pc}/address/save`, data, null, null, bag);
  },
  AddressDelete(data, bag) {
    //删除收货地址
    return post(`${domain.pc}/address/delete`, data, null, null, bag);
  },
  ExpressQuery(data, bag) {
    //物流查询
    return post(`${domain.pc}/express/query`, data, null, null, bag);
  },
  RegionList(data, bag) {
    //获取区域列表
    return get(`${domain.pc}/region/list`, data, null, null, bag);
  },
  OrderSubmit(data, bag) {
    //提交订单
    return post(`${domain.pc}/order/submit`, data, null, null, bag);
  },
  OrderPrepay(data, bag) {
    //订单的预支付会话
    return post(`${domain.pc}/order/prepay`, data, null, null, bag);
  },
  OrderList(data, bag) {
    //订单列表
    return get(`${domain.pc}/order/list`, data, null, null, bag);
  },
  OrderDetail(data, bag) {
    //订单详情
    return get(`${domain.pc}/order/detail`, data, null, null, bag);
  },
  GiftOrderDetail(data, bag) {
    //赠送自提订单的详情
    return get(`${domain.pc}/order/giftOrderDetail`, data, null, null, bag);
  },
  GiftOrderSend(data, bag) {
    //自提订单赠送发起
    return get(`${domain.pc}/order/giftOrderSend`, data, null, null, bag);
  },
  GiftOrderReceive(data, bag) {
    //自提订单赠送收取
    return get(`${domain.pc}/order/giftOrderReceive`, data, null, null, bag);
  },
  GiftOrderList(data, bag) {
    //自提赠送订单列表
    return get(`${domain.pc}/order/giftOrderlist`, data, null, null, bag);
  },
  ExpressTrace(data, bag) {
    //订单物流
    return get(`${domain.pc}/order/expressTrace`, data, null, null, bag);
  },
  OrderCancel(data, bag) {
    //取消订单
    return post(`${domain.pc}/order/cancel`, data, null, null, bag);
  },
  OrderRefund(data, bag) {
    //退款取消订单
    return post(`${domain.pc}/order/refund`, data, null, null, bag);
  },
  OrderDelete(data, bag) {
    //删除订单
    return post(`${domain.pc}/order/delete`, data, null, null, bag);
  },
  OrderConfirm(data, bag) {
    //确认收货
    return post(`${domain.pc}/order/confirm`, data, null, null, bag);
  },
  OrderGoods(data, bag) {
    //代评价商品信息
    return get(`${domain.pc}/order/goods`, data, null, null, bag);
  },
  OrderComment(data, bag) {
    //评价订单商品信息
    return post(`${domain.pc}/order/comment`, data, null, null, bag);
  },
  PreOrderRefund(data, bag) {
    //申请退货可退商品查询
    return get(`${domain.pc}/order/preOrderRefund`, data, null, null, bag);
  },
  RefundApply(data, bag) {
    //退货申请
    return post(`${domain.pc}/order/refundApply`, data, null, null, bag);
  },
  RefundOrderQuery(data, bag) {
    //退货单明细
    return get(`${domain.pc}/order/refundOrderQuery`, data, null, null, bag);
  },
  RefundUndo(data, bag) {
    //退货撤销
    return post(`${domain.pc}/order/refundUndo`, data, null, null, bag);
  },
  AddFreightMsg(data, bag) {
    //退货但快递信息补充
    return post(`${domain.pc}/order/addFreightMsg`, data, null, null, bag);
  },
  FetchVaild(data, bag) {
    //退货但快递信息补充
    return post(`${domain.pc}/order/fetchVaild`, data, null, null, bag);
  },
  FeedbackAdd(data, bag) {
    //添加反馈
    return post(`${domain.pc}/feedback/submit`, data, null, null, bag);
  },
  FootprintList(data, bag) {
    //足迹列表
    return get(`${domain.pc}/footprint/list`, data, null, null, bag);
  },
  FootprintDelete(data, bag) {
    //删除足迹
    return post(`${domain.pc}/footprint/delete`, data, null, null, bag);
  },
  UserFormIdCreate(data, bag) {
    //用户FromId，用于发送模版消息
    return post(`${domain.pc}/formid/create`, data, null, null, bag);
  },
  GroupOnList(data, bag) {
    //团购列表
    return get(`${domain.pc}/groupon/list`, data, null, null, bag);
  },
  GroupOn(data, bag) {
    //团购API-查询
    return get(`${domain.pc}/groupon/query`, data, null, null, bag);
  },
  GroupOnMy(data, bag) {
    //团购API-我的团购
    return get(`${domain.pc}/groupon/my`, data, null, null, bag);
  },
  GroupOnDetail(data, bag) {
    //团购API-详情
    return get(`${domain.pc}/groupon/detail`, data, null, null, bag);
  },
  GroupOnJoin(data, bag) {
    //团购API-详情
    return get(`${domain.pc}/groupon/join`, data, null, null, bag);
  },
  IsGroupOrder(data, bag) {
    //团购API-是否团购订单
    return get(`${domain.pc}/groupon/isGrouponOrder`, data, null, null, bag);
  },
  CouponList(data, bag) {
    //优惠券列表
    return get(`${domain.pc}/coupon/list`, data, null, null, bag);
  },
  CouponMyList(data, bag) {
    //我的优惠券列表
    return get(`${domain.pc}/coupon/mylist`, data, null, null, bag);
  },
  CouponSelectList(data, bag) {
    //当前订单可用优惠券列表
    return get(`${domain.pc}/coupon/selectlist`, data, null, null, bag);
  },
  CouponReceive(data, bag) {
    //优惠券领取
    return post(`${domain.pc}/coupon/receive`, data, null, null, bag);
  },
  CouponReceiveAll(data, bag) {
    //优惠券领取
    return post(`${domain.pc}/coupon/receiveAll`, data, null, null, bag);
  },
  CouponExchange(data, bag) {
    //优惠券兑换
    return post(`${domain.pc}/coupon/exchange`, data, null, null, bag);
  },
  GetUserCoupon(data, bag) {
    //用户个人可领取优惠券查询
    return get(`${domain.pc}/coupon/getUserCoupon`, data, null, null, bag);
  },
  StorageUpload(data, bag) {
    //图片上传
    return post(`${domain.pc}/storage/upload`, data, null, null, bag);
  },
  UserIndex(data, bag) {
    //个人页面用户相关信息
    return get(`${domain.pc}/user/index`, data, null, null, bag);
  },
  BrokerageMain(data, bag) {
    //佣金收益主页面
    return get(`${domain.pc}/brokerage/main`, data, null, null, bag);
  },
  SettleOrderList(data, bag) {
    //佣金收益主页面
    return get(`${domain.pc}/brokerage/settleOrderList`, data, null, null, bag);
  },
  ApplyWithdrawal(data, bag) {
    //佣金提现申请
    return post(`${domain.pc}/brokerage/applyWithdrawal`, data, null, null, bag);
  },
  ExtractList(data, bag) {
    //佣金账号提现记录
    return get(`${domain.pc}/brokerage/extractList`, data, null, null, bag);
  },
  ArticleDetail(data, bag) {
    //公告详情
    return get(`${domain.pc}/article/detail`, data, null, null, bag);
  },
  ApplyAgency(data, bag) {
    //代理申请
    return post(`${domain.pc}/user/applyAgency`, data, null, null, bag);
  },
  GetSharedUrl(data, bag) {
    //获取推广二维码
    return get(`${domain.pc}/user/getSharedUrl`, data, null, null, bag);
  },
  SeckillIndex(data, bag) {
    //获取秒杀批次信息
    return get(`${domain.pc}/seckill/batchTimes`, data, null, null, bag);
  },
  DoSeckill(data, bag) {
    //秒杀
    return get(`${domain.pc}/seckill/doSeckill`, data, null, null, bag);
  },
  SeckillList(data, bag) {
    //秒杀批次下商品查询
    return get(`${domain.pc}/seckill/batchGoods`, data, null, null, bag);
  },
  SubscribeSeckill(data, bag) {
    //订阅秒杀提醒消息
    return get(`${domain.pc}/seckill/subscribe`, data, null, null, bag);
  }
};

export default base;
