/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:09
 * @LastEditTime: 2022-07-29 14:50:39
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 配置接口请求域名
 * @FilePath: \SuperMall\src\common\api\domain.js
 */

import { setResponseFormat } from "./axios";

const domain = {
  pc: "/wx",
  // pc: "http://192.168.1.135:8088/wx",
};

const formatPC = (response) => {
  let object = {
    status: response.errno,
    message: response.errmsg,
  };

  object.data = response.data || response.rows;

  for (let key in response) {
    if (!~["data", "rows", "errno", "errmsg", "status", "message"].indexOf(key)) {
      object[key] = response[key];
    }
  }

  return object;
};

setResponseFormat(domain.pc + "/", formatPC);

export default domain;
