<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-29 15:58:52
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 17:18:52
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\components\userMenu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="zc-counter" :class="{ 'zc-counter-tiny': mode != 'normal' }">
		<template v-if="mode == 'normal'">
			<button class="zc-counter-mins" @mousedown="onMins" :disabled="minValue == model">-</button>
			<input v-model="model" class="zc-counter-input" autocomplete="off" type="number" :min="min" :max="max" :step="step" :disabled="disabled" @change="onChange" />
			<button class="zc-counter-plus" @mousedown="onPlus" :disabled="maxValue == model">
				<span>+</span>
				<b class="zc-counter-tip" v-if="tipMax && maxValue == model">{{tipMax.replace('${value}', maxValue)}}</b>
			</button>
		</template>
		<template v-else>
			<input v-model="model" class="zc-counter-input" autocomplete="off" type="number" :min="min" :max="max" :step="step" :disabled="disabled" @change="onChange" />
			<div class="zc-counter-group">
				<button class="zc-counter-plus" @mousedown="onPlus" :disabled="maxValue == model">
					<span>+</span>
					<b class="zc-counter-tip" v-if="tipMax && maxValue == model">{{tipMax.replace('${value}', maxValue)}}</b>
				</button>
				<button class="zc-counter-mins" @mousedown="onMins" :disabled="minValue == model">-</button>
			</div>
		</template>
		<div class="zc-counter-wait" v-show="wait"></div>
	</div>
</template>

<script>

export default {
	name: 'commonInputCounter',
	props: {
		value: {
			default: 0
		},
		min: {
			default: 0
		},
		max: {
			default: 0
		},
		disabled: {
			type: Boolean,
			default: false
		},
		wait: {
			type: Boolean,
			default: false
		},
		lazy: {
			type: Boolean,
			default: false
		},
		step: {
			type: Number,
			default: 1
		},
		tipMin: {
			type: String,
			default: ''
		},
		tipMax: {
			type: String,
			default: ''
		},
		mode: {
			type: String,
			default: 'normal'
		},
	},
	data()
	{
		return {
			model: 0,
			minValue: -Infinity,
			maxValue: Infinity
		}
	},
	watch:
	{
		value: {
			handler(n, o)
			{
				if (n != o && this.model != n)
				{
					this.model = parseFloat(n);

					if ((this.min == 0 || this.min) && this.model < parseFloat(this.min))
					{
						this.model = parseFloat(this.min);
					}
					else if ((this.max == 0 || this.max) && this.model > parseFloat(this.max))
					{
						this.model = parseFloat(this.max);
					}
				}
			},
			immediate: true
		},
		min: {
			handler(n, o)
			{
				if (n == 0 || n)
				{
					this.minValue = parseFloat(n);
				}
			},
			immediate: true
		},
		max: {
			handler(n, o)
			{
				if (n == 0 || n)
				{
					this.maxValue = parseFloat(n);
				}
			},
			immediate: true
		},
		model: {
			handler(n, o)
			{
			},
			immediate: true
		}
	},
	created()
	{
	},
	mounted()
	{
	},
	methods:
	{
		onFormat()
		{
			this.model = parseFloat(this.model);
			if ((this.min == 0 || this.min) && this.model < parseFloat(this.min))
			{
				this.model = parseFloat(this.min);
			}
			else if ((this.max == 0 || this.max) && this.model > parseFloat(this.max))
			{
				this.model = parseFloat(this.max);
			}

			this.$emit('change', this.model);
		},
		onChange()
		{
			clearTimeout(this.$timer);
			this.$timer = setTimeout(this.onFormat, 100);
		},
		onMins()
		{
			if (!this.disabled)
			{
				if ((this.min == 0 || this.min) && this.model - this.step < parseFloat(this.min))
				{
					this.model = parseFloat(this.min);
				}
				else
				{
					this.model -= this.step;
				}

				this.$emit('change', this.model);
			}
		},
		onPlus()
		{
			if (!this.disabled)
			{
				if ((this.max == 0 || this.max) && this.model + this.step > parseFloat(this.max))
				{
					this.model = parseFloat(this.max);
				}
				else
				{
					this.model += this.step;
				}

				this.$emit('change', this.model);
			}
		}
	}
}
</script>

<style scoped>
.zc-counter {
	border: solid 1px #ddd;
	user-select: none;
	height: 30px;
	display: inline-flex;
	border-radius: 5px;
	overflow: visible;
	position: relative;
	background-color: #FFF;
}

.zc-counter-wait {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.zc-counter-mins,
.zc-counter-plus {
	font-size: 18pt;
	font-weight: 100;
	font-family: none;
	width: 29px;
	height: 29px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	cursor: pointer;
	background-color: #f9f9f9;
	border: none;
	outline: none;
	position: relative;
}

.zc-counter-mins:disabled,
.zc-counter-plus:disabled {
	color: #ddd;
}

.zc-counter-input {
	border: none;
	outline: none;
	text-align: center;
	-moz-appearance: textfield;
	flex-grow: 1;
	flex-shrink: 1;
	display: block;
	border-left: solid 1px #ddd;
	border-right: solid 1px #ddd;
	min-width: 50px;
	background-color: transparent;
}

.zc-counter-input::-webkit-outer-spin-button,
.zc-counter-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.zc-counter-tip {
	font-size: 9pt;
	word-break: keep-all;
	white-space: nowrap;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	font-weight: normal;
	padding: 5px 10px;
	border-radius: 3px;
	transform: translateY(-150%);
	transition: all 0.25s ease;
	margin-top: -20px;
	z-index: -2;
	opacity: 0;
}

button:hover .zc-counter-tip {
	z-index: 2;
	opacity: 1;
	transform: translateY(-100%);
}

.zc-counter-tip:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px;
	border-color: rgba(0, 0, 0, 0.6) transparent transparent;
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
}

.zc-counter-group {
	display: flex;
	flex-direction: column;
}

.zc-counter-tiny {
	height: 39px;
}

.zc-counter-tiny .zc-counter-mins,
.zc-counter-tiny .zc-counter-plus {
	font-size: 18pt;
	font-weight: 100;
	font-family: none;
	width: 20px;
	height: 50%;
	font-size: 10.5pt;
}
.zc-counter-tiny .zc-counter-mins {
	border-radius: 0 0 5px 0;
}

.zc-counter-tiny .zc-counter-plus {
	border-bottom: solid 1px #ddd;
	border-radius: 0 5px 0 0;
}

.zc-counter-tiny .zc-counter-input {
	border-left: none;
	border-radius: 0 0 5px 5px;
}

</style>
