<template>
	<div>
		<mainHeader />
		<!--列表-->
		<mainNavigator complex @navclick="navclick" />
		<div class="py-container">
			<div class="yui3-g SortList">
				<div class="yui3-u Center banerArea" style="width: 100%">
					<!--banner轮播-->
					<div class="sui-carousel slide">
						<!-- <slider style="width: 100%;height: 450px">
              <div v-for="(item, index) in banner" :key="index">
                <img style="width: 100%; height: 450px" :src="item.url" />
              </div>
            </slider> -->
						<el-carousel height="454px">
							<el-carousel-item v-for="(item, index) in banner" :key="index">
								<img style="width: 100%; height: 100%" :src="item.url" />
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<!-- <div class="yui3-u Right">
          <div class="news">
            <h4>
              <em class="fl">公告</em>
            </h4>
            <div class="clearix"></div>
            <ul class="news-list unstyled">
              <li v-for="(item, index) in articles" :key="index" @click="showNotify(item)">
                <span class="bold">[{{ item.type == 1 ? "公告" : "通知" }}]</span>
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div class="topBtn1">
            <div class="topBtn2" @click="$router.push('/user/order/all')">我的订单</div>
            <div class="topBtn2" @click="$router.push('/user/favorite')">我的收藏</div>
            <div class="topBtn2" @click="$router.push('/user/index')">我的账户</div>
            <div class="topBtn2" @click="$router.push('/user/address')">我的地址</div>
          </div>
        </div> -->
			</div>
		</div>
		<!--推荐-->
		<div id="scrollStop3">
			<div v-for="(item1, index1) in topic1" :key="index1 + '1'">
				<div class="py-container">
					<div class="oneList">
						<ul class="oneList1">
							<li class="unit1 today" @click="jumpTopic(item1, '每日特价')">
								<img src="../../../assets/img/today.png" />
							</li>
							<template v-for="(item, index) in item1.topicGoods">
								<li class="unit1" :key="index" v-if="index < 4">
									<div>
										<div @click="jumpGoods(item)">
											<a>
												<img class="unit2" :src="item.picUrl" alt="" />
											</a>
											<div class="unit3">
												<p>{{ item.name }}</p>
												<span>
													¥
													<b>{{ item.price }}</b>
												</span>
											</div>
										</div>
									</div>
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="like">
      <div class="py-container">
        <div class="title">
          <h3 class="fl">新品推荐</h3>
          <b class="border"></b>
        </div>
        <div class="bd">
          <ul class="part1-a">
            <li class="unit1" v-for="(item, index) in newGoods" :key="index">
              <div :vars="(it = item.goods)">
                <div @click="jumpGoods(item)">
                  <a>
                    <img class="unit2" :src="it.picUrl" alt="" />
                  </a>
                  <div class="unit3">
                    <p>{{ it.name }}</p>
                    <span>
                      ¥
                      <b>{{ it.price }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
		<div id="scrollStop1" style="margin: 20px 0;">
			<div class="py-container py-container1" style="background-color:rgba(0,0,0,0)">
				<!-- <div class="title">
          <h3 class="fl">泽超心选</h3>
          <b class="border"></b>
        </div> -->
				<div class="integral1">
					<div class="integral2" v-for="(item, index) in integralListTask" @click="jumpTopic({ id: 'integral' }, '泽超心选', item)" :key="index">
						<img class="integral3" :src="item.picUrl" alt="" />
						<div class="integral4">{{ item.title }}</div>
						<div class="integral5">{{ item.subtitle }}</div>
					</div>
				</div>
				<!-- 
        <div class="bd">
          <ul class="part1-a">
            <li class="unit1" v-for="(item1, index1) in item.dtsGoods" :key="index1">
              <div :vars="(it1 = item1.goods)">
                <div @click="jumpGoods(item1)">
                  <a>
                    <img class="unit2" :src="it1.picUrl" alt="" />
                  </a>
                  <div class="unit3">
                    <p>{{ it1.name }}</p>
                    <span>
                      ¥
                      <b>{{ it1.price }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
			</div>
		</div>
		<div class="like" id="scrollStop2">
			<div class="py-container py-container1">
				<div class="title">
					<h3 class="fl">品牌闪购</h3>
					<b class="border"></b>
				</div>
				<div class="topicList">
					<li class="yui3-u-5-24" v-for="(item, index) in topic2" :key="index + '2'" @click="jumpTopic(item, '品牌闪购')">
						<img :src="item.picUrl" />
						<p>{{ item.title }}</p>
					</li>
				</div>
			</div>
		</div>
		<div class="like">
			<div class="py-container" @click="jumpTopic({ id: 'newGoods' }, '新品首发')">
				<img src="../../../assets/img/p1.png" style="width: 100%;border-radius: 8px" alt="" />
			</div>
		</div>
		<div class="like" v-if="hotGoods && hotGoods.length">
			<div class="py-container py-container1">
				<div class="title">
					<h3 class="fl">人气推荐</h3>
					<b class="border"></b>
				</div>
				<div class="bd">
					<ul class="part1-a">
						<li class="unit1" v-for="(item, index) in hotGoods" :key="index">
							<div :vars="(it = item.goods)">
								<div @click="jumpGoods(item)">
									<a>
										<img class="unit2" :src="it.picUrl" alt="" />
									</a>
									<div class="unit3">
										<p>{{ it.name }}</p>
										<span>
											¥
											<b>{{ it.price }}</b>
										</span>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<mainFooter />
		<!-- <mainDock /> -->
	</div>
</template>

<script>
import "@/assets/css/pages-JD-index.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";
import mainDock from "@/pc/components/dock";
import slider from "@/pc/components/slider";

export default {
	name: "homeIndex",
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		mainNavigator: mainNavigator,
		mainDock: mainDock,
		slider
	},
	data()
	{
		return {
			newGoods: [],
			hotGoods: [],
			topics: [],
			topic1: [],
			topic2: [],
			integralListTask: [],
			banner: [],
			articles: []
		};
	},
	computed: {
		user()
		{
			return this.$store.getters.user;
		},
		isLogin()
		{
			return this.$store.getters.isLogin;
		}
	},
	watch: {},
	created() { },
	mounted()
	{
		this.IndexUrl();
		if (sessionStorage.navclick)
		{
			setTimeout(() =>
			{
				this.navclick(sessionStorage.navclick);
			}, 500);
		}
	},
	methods: {
		navclick(i)
		{
			if (i == 3)
			{
				let div1 = document.querySelector("#scrollStop1");
				if (div1)
				{
					document.documentElement.scrollTop = document.documentElement.scrollTop + div1.getBoundingClientRect().top;
				}
			} else if (i == 4)
			{
				let div2 = document.querySelector("#scrollStop2");
				if (div2)
				{
					document.documentElement.scrollTop = document.documentElement.scrollTop + div2.getBoundingClientRect().top;
				}
			} else if (i == 5)
			{
				let div2 = document.querySelector("#scrollStop3");
				if (div2)
				{
					document.documentElement.scrollTop = document.documentElement.scrollTop + div2.getBoundingClientRect().top;
				}
			}
			setTimeout(() =>
			{
				sessionStorage.navclick = "";
			}, 100);
		},
		todayList(id, num)
		{
			this.$api.base
				.TopicDetail({
					id: id
				})
				.then((res) =>
				{
					this.topic1[ num ].topicGoods = res.goods.map((v1) =>
					{
						v1.price = Math.min.apply(
							null,
							v1.dtsGoodsProductList.map((v1) => v1.price)
						);
						return v1;
					});
					this.topic1 = JSON.parse(JSON.stringify(this.topic1));
				});
		},
		showNotify(i)
		{
			if (i.content)
			{
				uni.showModal({
					title: "公告",
					content: i.content
				});
			}
		},
		IndexUrl()
		{
			let that = this;
			this.$api.base.IndexUrl().then((res) =>
			{
				this.newGoods = res.newGoodsList;
				this.hotGoods = res.hotGoodsList;
				this.topics = res.topicList;
				this.integralListTask = res.integralListTask || [];
				this.banner = res.banner;
				this.articles = res.articles;

				that.topic1 = that.topics.filter((v1) =>
				{
					return v1.location == "1";
				});
				that.topic1.sort((a, b) =>
				{
					return b.sortOrder - a.sortOrder;
				});
				that.topic1.forEach((v1, index) =>
				{
					that.todayList(v1.id, index);
				});
				that.topic2 = that.topics
					.filter((v1) =>
					{
						return v1.location == "2";
					})
					.sort((a, b) =>
					{
						return b.sortOrder - a.sortOrder;
					});
				that.integralListTask.forEach((v1, index, arr) =>
				{
					v1.dtsGoods &&
						v1.dtsGoods.forEach((v2, index2, arr2) =>
						{
							arr2[ index2 ].goods.price = Math.min.apply(
								null,
								v2.products.map((v1) => v1.price)
							);
						});
					arr[ index ] = v1;
				});
				that.newGoods.forEach((v1, index, arr) =>
				{
					v1.goods.price = Math.min.apply(
						null,
						v1.products.map((v1) => v1.price)
					);
					arr[ index ] = v1;
				});
				that.hotGoods.forEach((v1, index, arr) =>
				{
					v1.goods.price = Math.min.apply(
						null,
						v1.products.map((v1) => v1.price)
					);
					arr[ index ] = v1;
				});
			});
		},
		GoodsCount()
		{
			this.$api.base.GoodsCount().then((res) =>
			{
				this.goodsCount = res.goodsCount;
			});
		},
		jumpTopic(i, j, k)
		{
			if (k)
			{
				localStorage.dtsGoods = JSON.stringify(k);
			} else
			{
				localStorage.dtsGoods = "";
			}
			let str = "/product/topic/";
			this.$router.push({
				path: str,
				query: {
					id: i ? i.id : j,
					type: j
				}
			});
		},
		jumpGoods(i)
		{
			let str = "/product/detail/";
			// let str = "/product/catalog/";
			if (i.goods)
			{
				str += i.goods.id;
			} else
			{
				str += i.id;
			}
			this.$router.push(str);
		}
	}
};
</script>

<style lang="scss" scoped>
.like {
	border: 0;
}
.title {
	padding: 10px 20px 0;
}
.py-container1 {
	background: #fff;
	border-radius: 10px !important;
	overflow: hidden;
}
.part1-a {
	display: flex;
	flex-wrap: wrap;
}
.part1-a,
.oneList1 {
	background-color: #fff;
	.unit1 {
		width: 180px;
		list-style: none;
		padding: 0 20px;
		background: #fff;
		border-radius: 8px;
	}
	.unit2 {
		width: 160px;
		height: 160px;
		margin: 10px auto 10px;
    border-radius: 5px;
	}
	.unit3 {
		span {
			color: #fff;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
			padding: 0px 10px;
			background: #f60;
			border-radius: 4px;
			margin-bottom: 10px;
			display: inline-block;
		}
		p {
			height: 50px;
			margin-bottom: 10px;
			line-height: 25px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
}
.news-list {
	height: 300px;
	overflow: hidden auto;
}
.topicList {
	li {
		width: 220px;
		margin: 10px;
		img {
			width: 210px;
			height: 210px;
      border-radius: 5px;
		}
		p {
			font-size: 16px;
			background: #fff;
			text-align: center;
			margin-top: 8px;
		}
	}
}
.oneList {
	overflow: auto hidden;
	.oneList1 {
		margin: 5px 0 0;
		display: flex;
		justify-content: flex-start;
		border-radius: 8px;
		overflow: hidden;
	}
	.unit1 {
		margin: 0 30px 0 0;
		&:nth-of-type(5) {
			margin: 0;
		}
	}
	.today {
		height: 288px;
		width: 220px;
		padding: 0;
		margin-right: 5px;
		img {
			border-radius: 8px 0 0 8px;
			width: 100%;
			height: 100%;
		}
	}
}
.topBtn1 {
	background: #fff;
	.topBtn2 {
		width: 50%;
		display: inline-block;
		padding: 17px 0;
		text-align: center;
		font-size: 16px;
		cursor: pointer;
	}
}

.integral1 {
	background: url('../../../assets/img/p2.jpg') no-repeat;
	background-size: 100%;
	border-radius: 8px;
	padding-top: 135px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.integral2 {
		padding: 10px;
		display: inline-block;
		background: #ffecba;
		border-radius: 8px;
		margin: 5px;
	}
	.integral3 {
		width: 206px;
		height: 210px;
		border-radius: 5px;
		margin-bottom: 10px;
	}
	.integral4 {
		text-align: center;
		font-size: 18px;
		color: #fff;
		line-height: 38px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 38px;
		background: url('../../../assets/images/p3.png') no-repeat;
		background-size: 100% 100%;
		width: 166px;
		padding: 0 20px;
	}
	.integral5 {
		width: 206px;
		margin-top: 7px;
		text-align: center;
		font-size: 18px;
		color: #f73900;
		line-height: 25px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
	}
}

.el-carousel--horizontal {
	border-radius: 8px;
}

.el-carousel__item {
	border-radius: 8px;
}
</style>
