<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 17:33:07
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-15 16:37:38
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\order\pay.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <mainHeader />
    <div class="py-container">
      <div class="zc-panel">
        <div class="zc-paragraph">
          <h2>订单提交成功，请您及时付款，以便尽快为您发货！</h2>
          <p>
            请您在提交订单
            <i>30分钟</i>
            之内完成支付，超时订单会自动取消，订单号：
            <i>{{ orderSn || orderId }}</i>
          </p>
          <p>
            应付金额：
            <i>￥{{ actualPrice }}</i>
          </p>

          <ul class="zc-pay-mode">
            <li @click="selectPayBtn(1)"><img src="/mock/img/_/pay3.jpg" /></li>
            <li @click="selectPayBtn(2)"><img src="/mock/img/_/pay2.jpg" /></li>
            <li @click="selectPayBtn(3)"><img src="/mock/img/icbc.png" style="height: 34px" /></li>
            <li @click="selectPayBtn(4)"><img src="/mock/img/ecny.png" style="height: 34px" /></li>
          </ul>
        </div>
      </div>
    </div>

    <Dialog :show.sync="loading2" :showCancel="true" confirmText="支付完成" title="使用微信扫描二维码支付" @fail="failFoo" @success="failFoo">
      <div style="text-align: center">
        <img style="width: 400px" :src="qrimg" alt="" />
      </div>
    </Dialog>
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "orderPay",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  data() {
    return {
      loading2: false,
      loading1: false,
      orderId: "",
      orderSn: "",
      actualPrice: 0,
      qrimg: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.orderSn = this.$route.query.orderSn;

    if (!this.orderId) {
      this.$router.replace("/");
    }
    this.actualPrice = this.$route.query.actualPrice;
  },
  methods: {
    failFoo(i) {
      if (i.confirm) {
        this.$router.replace({
          path: "/user/order/all",
          query: {
            orderId: this.orderId,
            actualPrice: this.actualPrice,
          },
        });
      } else {
      }
    },
    selectPayBtn(i) {
      this.OrderPrepay(i);
    },
    OrderPrepay(i) {
      if (this.loading1) return;
      this.loading1 = true;

      this.$api.base
        .OrderPrepay({
          orderId: this.orderId,
          payType: i,
        })
        .then((res) => {
          this.loading1 = false;
          if (i == 1) {
            uni.QRCode.toDataURL(res.codeUrl).then((res) => {
              this.qrimg = res;
              this.loading2 = true;
            });
          } else {
            const div1 = document.createElement("div");
            div1.innerHTML = res.body || res;
            document.body.appendChild(div1);
            div1.querySelector("form").setAttribute("target", "_blank");
            div1.querySelector("form").submit();
            setTimeout(() => {
              div1.remove();
            }, 500);

            uni.showModal({
              showCancel: true,
              confirmText: "支付完成",
              content: `<p style="font-size: 20px">请确认支付是否已完成</p>`,
              success: () => {
                this.$router.replace({
                  path: "/order/paySuccess",
                  query: {
                    orderId: this.orderId,
                    actualPrice: this.actualPrice,
                    payType: i,
                  },
                });
              },
              fail: () => {
                this.$router.push({
                  path: "/order/payFail",
                  query: {
                    orderId: this.orderId,
                    actualPrice: this.actualPrice,
                    payType: i,
                  },
                });
              },
            });
          }
        })
        .catch((err) => {
          this.loading1 = false;
        });
    },
  },
};
</script>

<style scoped>
.zc-pay-mode {
  display: flex;
  list-style: none;
  margin-top: 40px;
}

.zc-pay-mode li {
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 1px 1px 0;
  padding: 10px 20px;
  cursor: pointer;
}

.zc-pay-mode li:first-child {
  border-left-width: 1px;
}
</style>
