<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 17:28:24
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div>
		<mainHeader />
		<div class="py-container">
			<userMenu />
			<div class="yui3-u-5-6 zc-main">
				<div class="zc-panel">
					<div class="zc-panel-header zc-panel-header-fixed zc-panel-header-border">
						<h3 class="zc-panel-header-title">
							<strong>我的收货地址</strong>
							<small>您已创建 {{ addresses.length }} 个收货地址，最多可创建 25 个</small>
						</h3>
						<div class="zc-panel-header-operate">
							<a class="button-block button-big" @click="onEditAddress()" v-if="!addresses || addresses.length < 25">新增收货地址</a>
						</div>
					</div>
					<div class="zc-panel-container">
						<p class="zc-panel-empty" v-if="!addresses || !addresses.length">
							<img :src="require('@/assets/images/n2.png')" />
							<strong>暂无地址</strong>
						</p>
						<div class="zc-kv" v-for="(address, index) in addresses" :key="index">
							<div class="zc-kv-header">
								<h3 class="zc-kv-header-title">
									{{ address.name }}
									<sup v-if="address.isDefault">默认地址</sup>
								</h3>
								<div class="zc-kv-header-operate">
									<a class="button-block button-yellow" @click="onDefaultAddress(address)" v-if="!address.isDefault">设为默认</a>
									<a class="button-solid button-yellow" @click="onRemoveAddress(address)">删除</a>
									<a class="button-solid button-yellow" @click="onEditAddress(address)">编辑</a>
								</div>
							</div>
							<ul class="zc-kv-container">
								<li>
									<strong>收货人</strong>
									<small>{{ address.name }}</small>
								</li>
								<li>
									<strong>地址</strong>
									<small>{{ address.detailedAddress }}</small>
								</li>
								<li>
									<strong>手机</strong>
									<small>{{ address.mobile }}</small>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<mainFooter />

		<el-dialog title="编辑收货地址" :visible.sync="dialog.address" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog">
			<el-form ref="formAddress" :model="form.address" :rules="rules.address" label-position="top" hide-required-asterisk>
				<el-form-item label="收货人" prop="name">
					<el-input v-model="form.address.name" placeholder="请填写收货人姓名" maxlength="10" show-word-limit />
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input v-model="form.address.mobile" placeholder="请填写收货人手机号码" maxlength="11" show-word-limit />
				</el-form-item>
				<el-form-item label="所在地区" prop="region">
					<el-cascader v-model="form.address.region" :props="regionProps" @change="onSelectRegion"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input type="textarea" v-model="form.address.address" placeholder="请填写详细地址" maxlength="50" show-word-limit rows="3" resize="none" />
				</el-form-item>
				<el-form-item label="设为默认地址">
					<el-switch v-model="form.address.isDefault" active-color="#fa5302" :active-value="1" :inactive-value="0" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<a class="button-solid button-big" @click="dialog.address = false" style="margin-right:10px;">取消</a>
				<a class="button-block button-big" type="primary" @click="onSaveAddress">确定</a>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import userMenu from "@/pc/components/userMenu";

export default {
	name: "userAddress",
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		userMenu: userMenu
	},
	data()
	{
		return {
			addresses: [],
			dialog: {
				address: false
			},
			form: {
				address: {
					id: null,
					name: null,
					mobile: null,
					region: [],
					provinceId: null,
					cityId: null,
					areaId: null,
					address: null,
					isDefault: 1
				}
			},
			regionProps: {
				lazy: true,
				lazyLoad: (node, resolve) =>
				{
					this.$api.base.RegionList({ pid: node.level == 0 ? 0 : node.value }).then((response) =>
					{
						resolve(response.map((i) => Object.assign(i, { label: i.name, value: i.id, leaf: node.level >= 2 })));
					});
				}
			},
			rules: {
				address: {
					name: [
						{ required: true, message: "请填写收货人姓名", trigger: "blur" },
						{ min: 2, max: 10, message: "收货人姓名长度必须介于 2 和 10 之间", trigger: "blur" }
					],
					mobile: [
						{ required: true, message: "请填写收货人手机号码", trigger: "blur" },
						{ pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur" }
					],
					region: [ { required: true, type: "array", message: "请选择所在地区", trigger: "blur" } ],
					address: [
						{ required: true, message: "请填写详细地址", trigger: "blur" },
						{ min: 5, max: 50, message: "详细地址长度必须介于 5 和 50 之间", trigger: "blur" }
					]
				}
			}
		};
	},
	computed: {
		user()
		{
			return this.$store.getters.user;
		},
		isLogin()
		{
			return this.$store.getters.isLogin;
		}
	},
	watch: {},
	created()
	{
		this.getAddressList();
	},
	mounted() { },
	methods: {
		getAddressList()
		{
			this.$api.base
				.AddressList()
				.then((addresses) => (this.addresses = addresses.map((item) => Object.assign({ ...item }, { isDefault: item.isDefault ? 1 : 0, region: [ item.provinceId, item.cityId, item.areaId ] })).sort((a, b) => b.isDefault - a.isDefault)));
		},
		onSelectRegion(event)
		{
			if (event && event.length)
			{
				this.form.address.provinceId = event[ 0 ];
				this.form.address.cityId = event[ 1 ];
				this.form.address.areaId = event[ 2 ];
			} else
			{
				this.form.address.provinceId = null;
				this.form.address.cityId = null;
				this.form.address.areaId = null;
			}
		},
		onSaveAddress()
		{
			this.$refs[ "formAddress" ].validate((valid) =>
			{
				if (valid)
				{
					this.$api.base.AddressSave(this.form.address).then((response) =>
					{
						this.getAddressList();
						this.dialog.address = false;
					});
				}
			});
		},
		onEditAddress(model)
		{
			this.form.address = model || {
				id: null,
				name: null,
				mobile: null,
				region: [],
				provinceId: null,
				cityId: null,
				areaId: null,
				address: null,
				isDefault: 1
			};

			this.dialog.address = true;
		},
		onDefaultAddress(model)
		{
			this.$api.base.AddressSave(Object.assign({ ...model }, { isDefault: model.isDefault == 1 ? 0 : 1 })).then((response) =>
			{
				this.getAddressList();
			});
		},
		onRemoveAddress(model)
		{
			this.$api.base.AddressDelete(model).then((response) =>
			{
				this.getAddressList();
			});
		}
	}
};
</script>

<style scoped></style>
