<template>
  <div>
    <mainHeader />
    <div class="py-container">
      <div class="zc-panel">
        <div class="zc-panel-header">
          <h3 class="zc-panel-header-title">核对收件人信息</h3>
          <div class="zc-panel-header-operate">
            <a class="button-solid button-yellow button-big" @click="isShowMore = true" v-if="!isShowMore && addresses && addresses.length > 4">查看更多地址</a>
            <a class="button-block button-yellow button-big" @click="onEditAddress()" v-if="!addresses || addresses.length < 25">新增收货地址</a>
          </div>
        </div>
        <div class="zc-panel-container" v-if="addresses.length > 0">
          <ul class="zc-address">
            <li v-for="(address, index) in addresses.slice(0, 4)" :key="index" :class="{ 'zc-address-current': currentAddress == address }" @click="onSelectAddress(address)">
              <i class="el-icon-user-solid"></i>
              <h3>{{ address.name }}</h3>
              <p>
                {{ address.detailedAddress }}
                <span>{{ address.mobile }}</span>
              </p>
              <b v-if="address.isDefault">默认地址</b>
            </li>
            <template v-if="isShowMore">
              <li v-for="(address, index) in addresses.slice(4)" :key="index" :class="{ 'zc-address-current': currentAddress == address }" @click="onSelectAddress(address)">
                <i class="el-icon-user-solid"></i>
                <h3>{{ address.name }}</h3>
                <p>
                  {{ address.detailedAddress }}
                  <span>{{ address.mobile }}</span>
                </p>
                <b v-if="address.isDefault">默认地址</b>
              </li>
            </template>
          </ul>
        </div>
        <div v-else @click="selectAddress">还没有收货地址，去添加</div>
      </div>

      <div class="zc-panel">
        <div class="zc-panel-header">
          <h3 class="zc-panel-header-title">核对送货清单</h3>
        </div>
        <div class="zc-panel-container">
          <table class="zc-table">
            <colgroup>
              <col style="width:90px" />
              <col style="width:auto" />
              <col style="width:120px" />
              <col style="width:160px" />
              <col style="width:120px" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">商品</th>
                <th>单价（元）</th>
                <th>数量</th>
                <th>小计（元）</th>
              </tr>
            </thead>
            <tbody v-for="(bitem, index) in brandCartGoods" :key="index">
              <tr v-for="(item, index2) in bitem.cartList" :key="index2">
                <td>
                  <div class="isExistClass1">
                    <img :src="item.picUrl" />
                    <div class="isExistClass2" v-if="item.isExist == 0"></div>
                  </div>
                </td>
                <td style="text-align:left;">
                  <strong :class="{ isExistClass: item.isExist == 0 }">
                    {{ item.goodsName }}
                  </strong>
                  <small>规格：{{ item.specifications.toString() }}</small>
                  <span v-if="item.isExist == 0" class="isExistClass3">商品已失效</span>
                </td>
                <td :class="{ isExistClass: item.isExist == 0 }">￥{{ item.price }}</td>
                <td :class="{ isExistClass: item.isExist == 0 }">x{{ item.number }}</td>
                <td>
                  <i :class="{ isExistClass: item.isExist == 0 }">￥{{ bitem.bandGoodsTotalPrice }}</i>
                </td>
              </tr>
              <tr v-if="index === brandCartGoods.length - 1">
                <td>
                  运费:
                  <span>￥{{ defaultFreight ? bitem.bandFreightPrice : 0 }}</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr style="text-align:left;">
                <td colspan="5">
                  <div style="display:flex;justify-content:space-between;align-items:center;">
                    <span>
                      应付金额：
                      <i>￥{{ actualPrice }}</i>
                    </span>
                    <a class="button-block button-big" @click="submitOrder" style="width:100px;">提交订单</a>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <mainFooter />
    <el-dialog title="编辑收货地址" :visible.sync="dialog.address" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog">
      <el-form ref="formAddress" :model="form.address" :rules="rules.address" label-position="top" hide-required-asterisk>
        <el-form-item label="收货人" prop="name">
          <el-input v-model="form.address.name" placeholder="请填写收货人姓名" maxlength="10" show-word-limit />
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="form.address.mobile" placeholder="请填写收货人手机号码" maxlength="11" show-word-limit />
        </el-form-item>
        <el-form-item label="所在地区" prop="region">
          <el-cascader v-model="form.address.region" :props="regionProps" @change="onSelectRegion"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input type="textarea" v-model="form.address.address" placeholder="请填写详细地址" maxlength="50" show-word-limit rows="3" resize="none" />
        </el-form-item>
        <el-form-item label="设为默认地址">
          <el-switch v-model="form.address.isDefault" active-color="#fa5302" :active-value="1" :inactive-value="0" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <a class="button-solid button-big" @click="dialog.address = false" style="margin-right:10px;">取消</a>
        <a class="button-block button-big" type="primary" @click="onSaveAddress">确定</a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "orderConfirm",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  data() {
    return {
      isShowMore: false,
      cartIds: null,
      addresses: [],
      currentAddress: null,
      payFlag: false,
      popBoxDoor: false,
      popBoxData: {},
      isMultiOrderModel: 0,
      brandCartGoods: [],
      checkedGoodsList: [],
      checkedAddress: {},
      availableCouponLength: 0,
      // 可用的优惠券数量
      goodsTotalPrice: 0.0,
      //商品总价
      freightPrice: 0.0,
      //快递费
      couponPrice: 0.0,
      //优惠券的价格
      grouponPrice: 0.0,
      //团购优惠价格
      orderTotalPrice: 0.0,
      //订单总价
      actualPrice: 0.0,
      //实际需要支付的总价
      cartId: 0,
      addressId: 0,
      couponId: 0,
      message: "",
      grouponLinkId: 0,
      //参与的团购，如果是发起则为0
      grouponRulesId: 0,
      //团购规则ID
      defaultFreight: true,
      //配送方式，默认快递方式
      consignee: "",
      //自提 联系人
      consigneeMobile: "",
      //自提 联系人手机号
      fetchAddress: "",
      //自提取货地址
      longitude: 0,
      //店铺经度
      brandName: "",
      //店铺名称
      latitude: 0,
      //店铺维度
      seckilled: 0,
      // 标识是否为秒杀下单：1表示秒杀下单
      seckillGoodsId: 0,
      //秒杀商品id
      orderStatus: 2,
      dialog: {
        address: false,
      },
      form: {
        address: {
          id: null,
          name: null,
          mobile: null,
          region: [],
          provinceId: null,
          cityId: null,
          areaId: null,
          address: null,
          isDefault: 1,
        },
      },
      rules: {
        address: {
          name: [
            { required: true, message: "请填写收货人姓名", trigger: "blur" },
            { min: 2, max: 10, message: "收货人姓名长度必须介于 2 和 10 之间", trigger: "blur" },
          ],
          mobile: [
            { required: true, message: "请填写收货人手机号码", trigger: "blur" },
            { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur" },
          ],
          region: [{ required: true, type: "array", message: "请选择所在地区", trigger: "blur" }],
          address: [
            { required: true, message: "请填写详细地址", trigger: "blur" },
            { min: 5, max: 50, message: "详细地址长度必须介于 5 和 50 之间", trigger: "blur" },
          ],
        },
      },
      regionProps: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          this.$api.base.RegionList({ pid: node.level == 0 ? 0 : node.value }).then((response) => {
            resolve(response.map((i) => Object.assign(i, { label: i.name, value: i.id, leaf: node.level >= 2 })));
          });
        },
      },
    };
  },
  mounted: function() {
    this.getAddressList();

    try {
      let cartId = uni.getStorageSync("cartId");

      if (cartId) {
        this.setData({
          cartId: cartId,
        });
      }

      let addressId = uni.getStorageSync("addressId");

      if (addressId) {
        this.setData({
          addressId: addressId,
        });
      }

      let couponId = uni.getStorageSync("couponId");

      if (couponId) {
        this.setData({
          couponId: couponId,
        });
      }

      let grouponRulesId = uni.getStorageSync("grouponRulesId");

      if (grouponRulesId) {
        this.setData({
          grouponRulesId: grouponRulesId,
        });
      }

      let seckilled = uni.getStorageSync("seckilled");
      let seckillGoodsId = uni.getStorageSync("seckillGoodsId");

      if (seckilled && seckillGoodsId) {
        this.setData({
          seckilled: seckilled,
          seckillGoodsId: seckillGoodsId,
        });
      }
    } catch (e) {
      console.log(e);
    }

    this.getCheckoutInfo();
  },
  methods: {
    //获取checkou信息
    getCheckoutInfo: function() {
      let that = this;
      this.$api.base
        .CartCheckout({
          cartId: that.cartId,
          addressId: that.addressId,
          couponId: that.couponId,
          grouponRulesId: that.grouponRulesId,
          seckilled: that.seckilled,
          seckillGoodsId: that.seckillGoodsId,
        })
        .then(function(res) {
          let brandCartGoods = [];
          let checkedGoodsList = [];

          if (res.isMultiOrderModel === 1) {
            brandCartGoods = res.brandCartGoods;
          } else {
            checkedGoodsList = res.checkedGoodsList;
          }

          that.setData({
            isMultiOrderModel: res.isMultiOrderModel,
            brandCartGoods: brandCartGoods,
            checkedGoodsList: checkedGoodsList,
            checkedAddress: res.checkedAddress,
            availableCouponLength: res.availableCouponLength,
            actualPrice: res.actualPrice,
            couponPrice: res.couponPrice,
            grouponPrice: res.grouponPrice,
            freightPrice: res.freightPrice,
            goodsTotalPrice: res.goodsTotalPrice,
            orderTotalPrice: res.orderTotalPrice,
            addressId: res.addressId,
            couponId: res.couponId,
            grouponRulesId: res.grouponRulesId,
            cartIds: res.cartIds || [],
          });
        });
    },

    selectAddress() {
      uni.navigateTo({
        url: "/user/address",
      });
    },

    selectCoupon() {
      uni.navigateTo({
        url: "/pages/ucenter/couponSelect/couponSelect",
      });
    },

    bindMessageInput: function(e) {
      this.setData({
        message: e.detail.value,
      });
    },
    checkedFreight: function() {
      if (this.defaultFreight) {
        // 表示选择了自提
        //后台验证 1.如果选择配送是自提，则需判断当前下单是否有多个店铺，多个店铺不允许下自提订单，如果一个店铺则继续验证店铺是否录入了自提地址
        this.$api.base
          .FetchVaild({
            cartId: this.cartId,
            seckilled: this.seckilled,
            seckillGoodsId: this.seckillGoodsId,
          })
          .then((res) => {
            this.setData({
              fetchAddress: res.fetchAddress,
              longitude: res.longitude,
              latitude: res.latitude,
              brandName: res.brandName,
              defaultFreight: !this.defaultFreight,
              actualPrice: this.defaultFreight ? (new Number(this.actualPrice) - new Number(this.freightPrice)).toFixed(2) : (new Number(this.actualPrice) + new Number(this.freightPrice)).toFixed(2),
            });
          });
      } else {
        this.setData({
          fetchAddress: "",
          longitude: 0,
          latitude: 0,
          brandName: "",
          defaultFreight: !this.defaultFreight,
          actualPrice: this.defaultFreight ? (new Number(this.actualPrice) - new Number(this.freightPrice)).toFixed(2) : (new Number(this.actualPrice) + new Number(this.freightPrice)).toFixed(2),
        });
      }
    },
    showLocation: function(e) {
      let that = this;
      uni.openLocation({
        latitude: that.latitude,
        longitude: that.longitude,
        name: that.brandName,
        address: that.fetchAddress,
      });
    },

    bindInputMobile(e) {
      this.setData({
        consigneeMobile: e.detail.value,
      });
    },

    bindInputName(e) {
      this.setData({
        consignee: e.detail.value,
      });
    },
    icbc_payOrder() {
      this.popBoxDoor = false;
      this.$api("icbc_payOrder", this.popBoxData, "post")
        .then(() => {
          uni.redirectTo({
            url: "/pages/payResult/payResult?status=1&orderId=" + this.popBoxData.orderId,
          });
        })
        .catch(() => {
          uni.redirectTo({
            url: "/pages/payResult/payResult?status=0&orderId=" + this.popBoxData.orderId,
          });
        });
    },
    submitOrder() {
      //如果是默认快递方式，则要验证收货地址
      if (this.defaultFreight && !this.currentAddress) {
        uni.showToast({
          title: "请选择收货地址",
        });
        return false;
      } //自提方式需验证联系人及手机号码

      if (!this.defaultFreight && this.consignee == "") {
        uni.showToast({
          title: "请输入联系人姓名",
        });
        return false;
      }

      if (!this.defaultFreight && !check.isValidPhone(this.consigneeMobile)) {
        uni.showToast({
          title: "手机号不正确",
        });
        return false;
      }
      // this.popBoxDoor = true;
      // this.popBoxData = i;
      // this.popBoxDoor = false;
      let _this = this;

      let flag = [];
      this.brandCartGoods.forEach(function(v) {
        v.cartList.forEach(function(o) {
          if (o.isExist == 0) {
            flag.push(o);
          }
        });
      });
      if (flag.length) {
        uni.showToast({
          title: "存在已失效的商品，无法提交订单",
        });
        return;
      }

      // util.jhxLoadShow("正在下单，请稍后...");
      // let nowTime = +new Date();

      if (this.payFlag) return;
      this.payFlag = true;

      // if (nowTime - lastTime > 5000 || !lastTime) {
      //   //5秒内避免重复提交订单
      //   lastTime = nowTime
      // } else {
      //   return false
      // }

      let shareUserId = uni.getStorageSync("shareUserId");

      if (!shareUserId || shareUserId == "undefined") {
        //如果无真实的代理用户,则指定为默认第一个系统用户
        shareUserId = 1;
      }

      this.$api.base
        .OrderSubmit({
          cartIds: this.cartIds,
          cartId: this.cartId,
          addressId: this.currentAddress.id,
          couponId: this.couponId,
          message: this.message,
          grouponRulesId: this.grouponRulesId,
          grouponLinkId: this.grouponLinkId,
          defaultFreight: this.defaultFreight,
          consignee: this.consignee,
          consigneeMobile: this.consigneeMobile,
          shareUserId: shareUserId,
          seckilled: this.seckilled,
          seckillGoodsId: this.seckillGoodsId,
        })
        .then((res) => {
          _this.payFlag = false;
          // 下单成功，重置couponId
          try {
            uni.setStorageSync("couponId", 0);
          } catch (error) {}

          const orderId = res.orderId;

          this.$router.replace({
            path: "/order/pay",
            query: {
              orderId: res.orderId,
              orderSn: res.orderSn,
              actualPrice: _this.actualPrice,
            },
          });
        })
        .catch((err) => {
          this.payFlag = false;
          uni.showToast({
            title: err.errmsg || "付款失败",
            icon: "none",
          });
        });
    },
    getAddressList() {
      this.$api.base.AddressList().then((addresses) => {
        this.addresses = addresses.sort((a, b) => b.isDefault - a.isDefault);
        this.currentAddress = addresses.find((item) => item.isDefault);

        this.$forceUpdate();

        console.log(this.addresses, this.currentAddress);
      });
    },
    onSelectAddress(address) {
      this.currentAddress = address;
    },
    onSelectRegion(event) {
      if (event && event.length) {
        this.form.address.provinceId = event[0];
        this.form.address.cityId = event[1];
        this.form.address.areaId = event[2];
      } else {
        this.form.address.provinceId = null;
        this.form.address.cityId = null;
        this.form.address.areaId = null;
      }
    },
    onSaveAddress() {
      this.$refs["formAddress"].validate((valid) => {
        if (valid) {
          this.$api.base.AddressSave(this.form.address).then((response) => {
            this.getAddressList();
            this.dialog.address = false;
          });
        }
      });
    },
    onEditAddress(model) {
      this.form.address = model || {
        id: null,
        name: null,
        mobile: null,
        region: [],
        provinceId: null,
        cityId: null,
        areaId: null,
        address: null,
        isDefault: 1,
      };

      this.dialog.address = true;
    },
  },
};
</script>

<style scoped>
.zc-address {
  display: flex;
  flex-wrap: wrap;
}
.zc-address li {
  margin-bottom: 20px;
  cursor: pointer;
  width: 275px;
  /* height: 100px; */
  border: solid 1px #ddd;
  padding: 15px 15px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.zc-address li:nth-child(4n + 1) {
  margin: 10px 10px 10px 0;
}
.zc-address li:nth-child(4n + 2) {
  margin: 10px;
}
.zc-address li:nth-child(4n + 3) {
  margin: 10px;
}
.zc-address li:nth-child(4n + 4) {
  margin: 10px 0 10px 10px;
}

.zc-address li i {
  position: absolute;
  left: 15px;
  top: 20px;
  font-size: 13.5pt;
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.zc-address li p {
  display: flex;
  flex-direction: column;
  font-size: 10.5pt;
  margin: 0;
  transition: all 0.25s ease;
}

/* .zc-address li a {
  border: solid 1px #eee;
  font-size: 12pt;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-right: 20px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  font-weight: bold;
  transition: all 0.25s ease;
}

.zc-address li:hover {
  opacity: 1;
}

.zc-address li:hover a {
  border-color: #ccc;
} */

.zc-address li h3 {
  display: block;
  text-decoration: none;
  font-size: 13.5pt;
  line-height: 1;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
}

.zc-address li p {
  position: relative;
  display: flex;
  flex-direction: column;
}

.zc-address li p span {
  display: block;
  opacity: 0.75;
}

.zc-address li b {
  background-color: #eee;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  line-height: 1;
  height: 25px;
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 0 10px 3px;
  border-radius: 0 0 0 5px;
  /* transform: translate(50%, 50%) rotate(-45deg); */
}

li.zc-address-current {
  opacity: 1;
  padding: 14px 14px;
  border-color: #e4393c !important;
  border-width: 2px;
  /* background: linear-gradient(140deg, #fa530222 0%, #f7360233 100%); */
  color: #e4393c;
}

li.zc-address-current b {
  background-color: #e4393c !important;
  color: #fff !important;
}

/* 
li.zc-address-current:before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 39px;
	border-color: transparent #e4393c #e4393c transparent;
	position: absolute;
  z-index: 2;
	right: 0;
	bottom: 0;
}

li.zc-address-current a:after {
	content: '';
	display: block;
	width: 18px;
	height: 8px;
	border-left: solid 2px #fff;
	border-bottom: solid 2px #fff;
	transform: rotate(-50deg);
	position: absolute;
  z-index: 2;
	right: 1px;
	bottom: 5px;
} */

.isExistClass {
  color: #c5c5c5;
}
.isExistClass1 {
  position: relative;
}
.isExistClass2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
}
.isExistClass3 {
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 10px;
  background: #c5c5c5;
  color: #fff;
  margin-top: 10px;
  display: inline-block;
}
</style>
