<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:41:42
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-30 16:28:37
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="zc-footer">
		<div class="py-container">
			<ul class="zc-footer-service">
				<li>
					<img :src="require('@/assets/images/footer/footer.1.png')" />
					<div>
						<h4>正品保障</h4>
						<p>正品行货，极致服务</p>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/images/footer/footer.2.png')" />
					<div>
						<h4>急速配送</h4>
						<p>多仓直发，急速发货</p>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/images/footer/footer.3.png')" />
					<div>
						<h4>好物集结</h4>
						<p>新品丰富，轻松购物</p>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/images/footer/footer.4.png')" />
					<div>
						<h4>大牌汇聚</h4>
						<p>超值优惠，精致生活</p>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/images/footer/footer.5.png')" />
					<div>
						<h4>省心服务</h4>
						<p>专业客服，售后无忧</p>
					</div>
				</li>
			</ul>
			<p class="zc-copyright"><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备17013344号-2</a>　版权所有 Copyright &copy; 2009-2020 江苏泽超文化发展有限公司</p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'commonFooter',
	data()
	{
		return {
		}
	},
	computed: {
		user()
		{
			return this.$store.getters.user
		},
		isLogin()
		{
			return this.$store.getters.isLogin
		}
	},
	watch:
	{
	},
	created()
	{
	},
	mounted()
	{
	},
	methods:
	{
	}
}
</script>

<style scoped>
.zc-footer {
	background-color: #eaeaea;
	padding: 40px 0 20px;
}

.zc-footer-service {
	display: flex;
	justify-content: space-between;
	border-bottom: solid 1px #DDD;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.zc-footer-service img {
	margin-right: 10px;
}
/* 
.zc-footer-service .zc-footer-icon {
	display: block;
	background-image: url(../../assets/img/icons.png);
	width: 56px;
	height: 50px;
	margin-right: 10px;
}

.zc-footer-service .zc-footer-icon-01 {
	background-position: 305px 0;
}
.zc-footer-service .zc-footer-icon-02 {
	background-position: 305px -50px;
}
.zc-footer-service .zc-footer-icon-03 {
	background-position: 305px -103px;
}
.zc-footer-service .zc-footer-icon-04 {
	background-position: 305px -153px;
}
.zc-footer-service .zc-footer-icon-05 {
	background-position: 305px -205px;
} */

.zc-footer-service li {
	display: flex;
	align-items: center;
}

.zc-footer-service li h4 {
	margin: 0 0 5px;
	font-size: 12pt;
}

.zc-footer-service li p {
	margin: 0;
	color: #666;
}

.zc-copyright {
	margin: 0;
	text-align: center;
	color: #999;
	font-size: 9pt;
}
</style>
