<template>
  <div>
    <mainHeader />
    <mainNavigator />

    <div class="main">
      <div class="py-container">
        <!--bread-->
        <div class="bread">
          <ul class="fl sui-breadcrumb">
            <li>
              <a @click="$router.push('/')">首页</a>
            </li>
            <li class="active">公告</li>
          </ul>
          <!-- <ul class="fl sui-tag">
            <li class="with-x">
              iphone
              <i>×</i>
            </li>
          </ul>
          <form class="fl sui-form form-dark">
            <div class="input-control control-right">
              <input type="text" />
              <i class="sui-icon icon-touch-magnifier"></i>
            </div>
          </form> -->
        </div>
        <!--selector-->
        <!-- <div class="clearfix selector">
          <div class="type-wrap">
            <div class="key">{{ navTitle }}</div>
            <div class="value">
              <ul class="type-list">
                <li v-for="(item, index) in navList" :key="index" :class="id == item.id ? 'active' : ''" :data-id="item.id" :data-index="index" @click="switchCate">
                  <a>{{ item.name }}</a>
                </li>
              </ul>
            </div>
            <div class="fl ext"></div>
          </div>
        </div> -->
        <!--details-->
        <div class="details">
          <div class="goods-list">
            <div style="text-align: center;font-size: 16px" v-if="!noticeList || !noticeList.length">
              <img style="width: 200px" src="../../../assets/images/n2.png" alt="" />
              暂无公告
            </div>
            <div class="partA-wrap" v-else>
              <div class="partA" v-for="(item, index) in noticeList" :key="index">
                <div class="partAa">
                  <div class="partAa-b" @click="showCtx(item)">
                    {{ item.title }}
                    <span class="partAa-b1">{{ item.addTime }}</span>
                  </div>
                  <div class="partAa-c" v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter />
  </div>
</template>

<script>
import "@/assets/css/pages-list.css";

import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";
import mainNavigator from "@/pc/components/navigator";

export default {
  name: "productTopic",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
    mainNavigator: mainNavigator
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    }
  },
  data() {
    return {
      noticeList: []
    };
  },
  mounted: function() {
    this.$api.base.getArticles().then((res) => {
      this.noticeList = res.articles;
    });
  },
  methods: {
    showCtx(i) {
      if (i.content) {
        uni.showModal({
          title: "公告",
          content: i.content
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.part-empty {
  text-align: center;
}
.goods-list {
  background: #fff;
  border-radius: 4px;
  padding: 0 40px;
  .partA {
    font-size: 20px;
    font-weight: 600;
    color: #212223;
    line-height: 28px;
    border-bottom: 1px solid rgba(170, 172, 174, 0.3);
    padding: 24px 0;
  }
  .partAa {
    cursor: pointer;
  }
  .partAa-b1 {
    font-size: 16px;
    font-weight: 400;
    color: #b5b6b8;
    line-height: 22px;
    margin-left: 20px;
    vertical-align: bottom;
  }
  .partAa-c {
    font-size: 16px;
    font-weight: 400;
    color: #898b8d;
    line-height: 25px;
    margin-top: 8px;
    max-height: 50px;
    overflow: hidden;
  }
}
</style>
