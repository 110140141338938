/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-08-15 16:25:03
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import api from "@/common/api";

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import unicom from "../assets/js/unicom";
Vue.use(unicom);

Vue.prototype.$api = api;

Vue.config.productionTip = false;

Vue.use(Element);

new Vue({
  router,
  store,
  created() {},
  mounted() {},
  render: (h) => h(App)
}).$mount("#app");
