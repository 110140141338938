/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-08-17 14:34:13
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\store.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const config = {
	state: {
		user: localStorage['userInfo'] ? JSON.parse(localStorage['userInfo']) : null,
		token: localStorage['token'],
		dynamicOrderRefund: null
	},
	getters: {
		user: (state) =>
		{
			return state.user;
		},
		isLogin: (state) =>
		{
			return !!state.token;
		},
		dynamicOrderRefund: (state) =>
		{
			return state.dynamicOrderRefund;
		}
	},
	mutations: {
		setUser: (state, data) =>
		{
			state.user = data;
		},
		setToken: (state, data) =>
		{
			state.token = data;
		},
		setLogout: (state, data) =>
		{
			state.user = null;
			state.token = '';

			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
		},
		setDynamicOrderRefund: (state, data) =>
		{
			state.dynamicOrderRefund = data;

			setTimeout(() =>
			{
				state.dynamicOrderRefund = null;
			}, 5000);
		}
	},
	actions: {},
	modules: {},
}

export default new Vuex.Store(config)
