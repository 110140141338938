<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-03 16:03:33
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-17 15:23:47
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\components\slider.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="zc-slider">
		<div class="zc-slider-container">
			<div class="zc-slider-track" :style="{ transform: `translateX(-${ index * 100 }%)`}">
				<slot></slot>
			</div>
		</div>
		<div class="zc-slider-relative" v-if="relative && size > 1">
			<a class="zc-slider-relative-previous" @click="onPrevious">‹</a>
			<a class="zc-slider-relative-next" @click="onNext">›</a>
		</div>
		<div class="zc-slider-navigator" v-if="navigator && size > 1">
			<ul>
				<li :class="{ 'zc-slider-navigator-current': index == i }" v-for="(item, i) in frames" :key="i" @click="onStep(i)">{{i + 1}}</li>
			</ul>
		</div>
	</div>
</template>

<script>

export default {
	name: 'commonSlider',
	props: {
		navigator: {
			type: Boolean,
			default: false,
			required: false
		},
		relative: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data()
	{
		return {
			size: 0,
			index: 0,
			frames: []
		}
	},
	watch:
	{
		'$slots.default': {
			handler(n, o)
			{
				if (n)
				{
					this.size = n.length;
					this.frames = new Array(this.size);
				}
			},
			immediate: true
		}
	},
	updated()
	{
		if (this.$slots.default && this.size != this.$slots.default.length)
		{
			console.log('updated', this.$slots.default);

			this.size = this.$slots.default.length;
			this.frames = new Array(this.size);
		}
	},
	created()
	{
		console.log('created', this.$slots.default);
	},
	mounted()
	{
		console.log('mounted', this.$slots.default);
	},
	methods:
	{
		onPrevious()
		{
			this.index == 0 ? this.index = this.size - 1 : this.index--;
		},
		onNext()
		{
			this.index == this.size - 1 ? this.index = 0 : this.index++;
		},
		onStep(index)
		{
			this.index = index;
		}
	}
}
</script>

<style scoped>
.zc-slider {
	position: relative;
	width: 400px;
	height: 300px;
	user-select: none;
}

.zc-slider-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.zc-slider-track {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	transition: all 0.4s ease;
}

.zc-slider-track > * {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	flex-shrink: 0;
}

.zc-slider-relative-previous,
.zc-slider-relative-next {
	position: absolute;
	z-index: 2;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	width: 22px;
	height: 40px;
	font-size: 24pt;
	color: #fff;
	background-color: #ddd;
	text-decoration: none;
	text-align: center;
	line-height: 1.1;
	opacity: 0.4;
}

.zc-slider-relative-previous:hover,
.zc-slider-relative-next:hover {
	opacity: 0.8;
}

.zc-slider-relative-previous {
	left: 0;
}

.zc-slider-relative-next {
	right: 0;
}
.zc-slider-navigator {
	display: flex;
	justify-content: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.zc-slider-navigator ul {
	list-style: none;
	display: flex;
}

.zc-slider-navigator ul li {
	width: 8px;
	height: 0;
	padding-top: 8px;
	border-radius: 100%;
	overflow: hidden;
	background-color: #ccc;
	opacity: 0.5;
	cursor: pointer;
	margin: 0 2px;
}

.zc-slider-navigator ul li:hover {
	opacity: 0.75;
}

.zc-slider-navigator-current {
	background-color: #fa5302 !important;
}
</style>
