<template>
  <div>
    <mainHeader />
    <div class="zc-login">
      <div class="zc-login-panel">
        <div class="zc-login-form">
          <h3>账户登录</h3>
          <div class="zc-login-item">
            <img class="zc-login-icon" :src="require('@/assets/images/icon_account.png')" />
            <input type="text" v-model="userForm.phone" placeholder="请输入手机号码(新手机号自动注册)" />
          </div>
          <div class="zc-login-item">
            <img class="zc-login-icon" :src="require('@/assets/images/icon_password.png')" />
            <input type="text" v-model="userForm.code" autocomplete="off" placeholder="请输入验证码" />
            <span class="zc-login-code" @click="getMsgCode">{{ msgCodeTxt }}</span>
          </div>
          <a class="zc-login-button" @click="AuthLoginByAccount">登&nbsp;&nbsp;录</a>
        </div>
      </div>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from "@/pc/components/header";
import mainFooter from "@/pc/components/footer";

export default {
  name: "userLogin",
  components: {
    mainHeader: mainHeader,
    mainFooter: mainFooter,
  },
  data() {
    return {
      userForm: {
        phone: "",
      },
      getMsgDoor: false,
      msgCodeTxt: "获取验证码",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {
    if (!localStorage.token) {
      this.$store.commit("setLogout");
    }
  },
  mounted() {},
  methods: {
    getMsgCode() {
      if (!this.userForm.phone.match(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)) {
        uni.showToast({
          title: "请输入正确的手机号",
        });
        return;
      }
      if (this.getMsgDoor) return;
      this.getMsgDoor = true;
      this.sendCode();
      let num = 60;
      const loop = () => {
        if (num > 0) {
          num = num - 1;
          this.msgCodeTxt = num + "s";
          setTimeout(() => {
            if (!this.getMsgDoor) {
              num = 0;
            }
            loop();
          }, 1000);
        } else {
          this.getMsgDoor = false;
          this.msgCodeTxt = "获取验证码";
        }
      };
      loop();
    },
    sendCode() {
      this.$api.base
        .regCaptcha({
          mobile: this.userForm.phone,
        })
        .then((res) => {
          uni.showToast({
            title: "发送成功",
          });
        })
        .catch((err) => {
          uni.showToast({
            title: "发送失败",
          });
        });
    },
    // AuthRegister() {
    //   let send = {
    //     username: this.userForm.phone,
    //     password: "123456",
    //     mobile: this.userForm.code,
    //     code: "1234",
    //     wxCode: "7ef294fe-79d1-4098-8050-5abefd1272c6"
    //   };
    //   this.$api.base.AuthRegister(send);
    // },
    AuthLoginByAccount() {
      this.$api.base
        // .AuthRegister({
        .AuthLoginByAccount({
          code: this.userForm.code,
          mobile: this.userForm.phone,
        })
        .then((res) => {
          localStorage.userInfo = JSON.stringify(res.userInfo);
          localStorage.token = res.token;

          this.$store.commit("setUser", res.userInfo);
          this.$store.commit("setToken", res.token);
          localStorage.removeItem("addressId");

          // this.$router.replace("/");
          location.href = "/";
        })
        .catch((err) => {
          uni.showToast({
            title: err.errmsg || "登录失败",
          });
        });
    },
  },
};
</script>

<style scoped>
.zc-login {
  background-color: #7e00ff;
  height: 485px;
  min-width: 1115px;
}

.zc-login-panel {
  background-image: url(../../../assets/img/loginbg.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 485px;
  width: 1115px;
  box-sizing: border-box;
  padding-left: 700px;
  padding-top: 100px;
  margin: 0 auto;
}

.zc-login-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.zc-login-form h3 {
  font-size: 13.5pt;
}

.zc-login-item {
  border: solid 2px #ddd;
  display: flex;
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  font-size: 10.5pt;
}

.zc-login-item input {
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 45px;
  outline: none;
}

.zc-login-icon {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
}

.zc-login-code {
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
  color: #7e00ff;
  cursor: pointer;
}

.zc-login-button {
  background-color: #7e00ff;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  font-size: bold;
  margin-top: 20px;
  font-size: 10.5pt;
  text-decoration: none;
  cursor: pointer;
}
</style>
