import { render, staticRenderFns } from "./dock.vue?vue&type=template&id=4e60b66c&scoped=true&"
import script from "./dock.vue?vue&type=script&lang=js&"
export * from "./dock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e60b66c",
  null
  
)

export default component.exports