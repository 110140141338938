<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-25 11:35:25
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
	<div>
		<mainHeader />
		<div class="py-container">
			<userMenu />
			<div class="yui3-u-5-6 zc-main">
				<div class="zc-panel">
					<div class="zc-panel-header">
						<h3 class="zc-panel-header-title">订单信息</h3>
					</div>
					<ul class="zc-kv-container">
						<li>
							<strong>收货地址</strong>
							<small>{{order.address}}</small>
						</li>
						<li>
							<strong>订单单号</strong>
							<small>{{order.orderSn}}</small>
						</li>
						<li>
							<strong>下单时间</strong>
							<small>{{order.addTime}}</small>
						</li>
						<li>
							<strong>支付时间</strong>
							<small>{{order.payTime || '-'}}</small>
						</li>
						<li>
							<strong>支付方式</strong>
							<small>{{payTypes[order.payType]}}</small>
						</li>
						<li>
							<strong>发货时间</strong>
							<small>{{order.shipTime || '-'}}</small>
						</li>
						<li>
							<strong>物流公司</strong>
							<small>{{order.expName || '-'}}</small>
						</li>
						<li>
							<strong>物流单号</strong>
							<small>{{order.expNo || '-'}}</small>
						</li>
						<li>
							<strong>收货时间</strong>
							<small>{{order.confirmTime || '-'}}</small>
						</li>
					</ul>
				</div>
				<div class="zc-panel">
					<div class="zc-panel-container">
						<table class="zc-table-title">
							<colgroup>
								<col style="width:50px;" />
								<col style="width:auto;" />
								<col style="width:80px;" />
								<col style="width:80px" />
								<col style="width:100px" />
								<col style="width:120px" />
								<col style="width:120px" />
							</colgroup>
							<thead>
								<tr>
									<th>
										<el-checkbox :value="orderGoods.filter(item => item.checked).length == orderGoods.length" @change="onToggleAll" />
									</th>
									<th>宝贝</th>
									<th>单价</th>
									<th>数量</th>
									<th>商品操作</th>
									<th>实付款</th>
									<th>交易状态</th>
								</tr>
							</thead>
						</table>
						<div class="zc-table-item">
							<table class="zc-table-content">
								<colgroup>
									<col style="width:50px;" />
									<col style="width:auto;" />
									<col style="width:80px;" />
									<col style="width:80px" />
									<col style="width:100px" />
									<col style="width:120px" />
									<col style="width:120px" />
								</colgroup>
								<tbody>
									<tr v-for="(goods, m) in orderGoods" :key="m" :temp="span = orderGoods.length" :class="{ 'zc-tr-disabled': !goods.isExist }">
										<template>
											<td>
												<el-checkbox v-model="goods.checked" :disabled="!!goods.refundId && goods.refundStatus != 2" @change="onToggle" />
											</td>
											<td>
												<div class="zc-figure-text">
													<div class="zc-figure-image">
														<img :src="goods.picUrl" />
													</div>
													<h3>
														<strong>
															<a @click="$router.push({ path: `/product/detail/${goods.goodsId}` })" v-if="goods.isExist">{{goods.goodsName}}</a>
															<span v-else>{{goods.goodsName}}</span>
														</strong>
														<small>规格：{{goods.specifications.join(' ')}}</small>
													</h3>
												</div>
											</td>
											<td>
												<ul>
													<li>¥{{goods.price}}</li>
												</ul>
											</td>
											<td>x{{goods.number}}</td>
											<td>
												<a class="button-solid button-yellow" v-if="order.handleOption.rebuy && goods.isExist" @click="onOrderAgain(goods.goodsId)">再次购买</a>
												<a class="button-solid button-yellow" v-if="goods.refundId" @click="onRefundDetail(goods.refundId)">{{goods.refundStatus == 2 ? '审批拒绝' : '退货查看'}}</a>
												<span v-if="!(order.handleOption.rebuy && goods.isExist) && !goods.refundId">-</span>
											</td>
										</template>
										<template v-if="m == 0">
											<td :rowspan="span">
												<ul>
													<li>¥{{order.actualPrice}}</li>
													<li>（含运费：￥{{order.freightPrice}}）</li>
												</ul>
											</td>
											<td :rowspan="span">
												<ul>
													<li>{{ order.orderStatusText }}</li>
												</ul>
											</td>
										</template>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="zc-panel">
					<div class="zc-panel-header">
						<h3 class="zc-panel-header-title">退货原因</h3>
						<div class="zc-text">退款金额：<i>￥{{form.refundGoodsAmt}}</i></div>
					</div>
					<div class="zc-panel-container">
						<el-input v-model="form.refundReason" rows="5" resize="none" type="textarea" maxlength="200" show-word-limit placeholder="请联系客服确认后，录入退货(退款)申请理由，点击申请……"></el-input>
					</div>

					<div style="display:flex;padding:10px 0;justify-content:flex-end;">
						<a class="button-block button-big" type="primary" @click="onApply">退款申请</a>
					</div>
				</div>
			</div>
		</div>
		<mainFooter />

		<el-dialog title="退款详情" :visible.sync="dialog.refund" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog  zc-dialog-detail">
			<el-form label-position="left" label-width="100px" size="mini">
				<el-form-item label="退单编号">
					<span>{{ form.refund.refundSn }}</span>
				</el-form-item>
				<el-form-item label="申请时间">
					<span>{{ form.refund.addTime }}</span>
				</el-form-item>
				<el-form-item label="退款状态">
					<span>{{ form.refund.status == 0 ? "等待审批" : form.refund.status == 2 ? "审批拒绝" : "审批通过" }}</span>
				</el-form-item>
				<el-form-item label="拒绝原因" v-if="form.refund.status == 2">
					<span>{{ form.refund.approveMsg }}</span>
				</el-form-item>
				<el-form-item label="申请总额">
					<span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
					<span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
				</el-form-item>
				<el-form-item label="审批总额" v-if="form.refund.status == 1 || form.refund.status == 3">
					<span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
					<span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
				</el-form-item>
				<el-form-item label="原订单编号">
					<span>{{ form.refund.orderSn }}</span>
				</el-form-item>
				<el-form-item label="收货人">
					<span>{{ form.refund.consignee }}</span>
				</el-form-item>
				<el-form-item label="联系方式">
					<span>{{ form.refund.mobile }}</span>
				</el-form-item>
			</el-form>
			<img style="position:absolute;right:10px;bottom:30px;" :src="form.refund.status == 0 ? require('../../../assets/images/wait.png') : form.refund.status == 2 ? require('../../../assets/images/reject.png') : require('../../../assets/images/pass.png')" />
		</el-dialog>
	</div>
</template>
<script>
import '@/assets/css/pages-seckillOrder.css';

import mainHeader from '@/pc/components/header';
import mainFooter from '@/pc/components/footer';
import userMenu from '@/pc/components/userMenu';
import productCard from '@/pc/components/productCard';

export default {
	name: 'userOrderDetail',
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		userMenu: userMenu,
		productCard: productCard
	},
	data()
	{
		return {
			payTypes: {
				0: '未支付',
				1: '微信支付',
				2: '支付宝',
        3: '工行e支付'
			},
			order: {},
			orderGoods: [],
			form: {
				orderId: this.$route.params.id,
				productIds: [],
				orderGoodsIds: [],
				refundGoodsAmt: 0,
				refundReason: '',
				refund: {}
			},
			dialog: {
				refund: false
			}
		}
	},
	computed: {
		user()
		{
			return this.$store.getters.user
		},
		isLogin()
		{
			return this.$store.getters.isLogin
		}
	},
	watch:
	{
	},
	created()
	{
		this.getOrderDetail(this.$route.params.id);
	},
	mounted()
	{
	},
	methods:
	{
		getOrderDetail(id)
		{
			this.$api.base.OrderDetail({ orderId: id }).then(response =>
			{
				this.order = response.orderInfo;
				this.orderGoods = response.orderGoods.map(item =>
				{
					item.checked = true;
					return item;
				});

				this.setStatus();
			});
		},
		onToggleAll(event)
		{
			this.orderGoods.forEach(item =>
			{
				if (!item.refundId || item.refundStatus == 2)
				{
					item.checked = event;
				}
			});

			this.setStatus();
		},
		onToggle()
		{
			this.setStatus();
		},
		setStatus()
		{
			let amount = 0;
			let products = [];
			let orderGoodsIds = [];

			this.orderGoods.forEach(item =>
			{
				if (item.checked && (!item.refundId || (item.refundId && item.refundStatus == 2)))
				{
					amount += item.price * item.number;
					products.push(item.productId);
					orderGoodsIds.push(item.id);
				}
			});

			if (this.isAllBack() && ~[ 201, 204, 205 ].indexOf(this.order.orderStatus))
			{
				amount = amount + this.order.freightPrice;
			}

			this.form.productIds = products;
			this.form.orderGoodsIds = orderGoodsIds;
			this.form.refundGoodsAmt = Number(amount).toFixed(2);
		},
		isAllBack()
		{
			//return this.orderGoods.filter(item => !!item.refundId || item.checked).length == this.orderGoods.length;
			return this.orderGoods.filter(item => !!~[1, 3].indexOf(item.refundStatus) || item.checked).length == this.orderGoods.length;
		},
		onApply()
		{
			if (!this.form.productIds.length)
			{
				uni.showToast({ title: "请选择退货商品" });
				return;
			}

			if (!this.form.refundReason.trim())
			{
				uni.showToast({ title: "请填写退货原因" });
				return;
			}

			this.form.refundReason = (this.form.refundReason || '').trim();

			this.$api.base.RefundApply(this.form).then(response =>
			{
				this.$store.commit('setDynamicOrderRefund', response.refundId);
				this.$router.replace({ path: '/user/order/all' });
			});
		},
		onRefundDetail(id)
		{
			this.$api.base.RefundOrderQuery({ refundId: id }).then(response =>
			{
				this.form.refund = Object.assign(response.refundTrace, { consignee: response.orderInfo.consignee, mobile: response.orderInfo.mobile });
				this.dialog.refund = true;
			});
		}
	}
}
</script>

<style scoped>
.zc-progress {
	display: flex;
	padding: 10px 40px 0;
	list-style: none;
	text-align: center;
	counter-reset: progress;
	justify-content: space-between;
	color: #999;
}

.zc-progress li {
	width: 35px;
	height: 35px;
	display: block;
	position: relative;
	counter-increment: progress;
}

.zc-progress li:before {
	content: counter(progress);
	display: flex;
	width: 35px;
	height: 35px;
	border-radius: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 10.5pt;
	font-weight: bold;
	background: #ddd;
}
.zc-progress li:after {
	content: '';
	display: block;
	height: 6px;
	width: 220px;
	background: #ddd;
	position: absolute;
	left: 45px;
	top: 15px;
	border-radius: 3px;
}

.zc-progress li:last-child:after {
	display: none;
}

.zc-progress li.active {
	color: #fa5302;
}

.zc-progress li.active:before {
	background: linear-gradient(140deg, #fa5302 0%, #f73602 100%);
}

.zc-progress li.active:after {
	background-color: #fa5302;
}

.zc-progress li p {
	width: 120px;
	position: absolute;
	left: 50%;
	top: 40px;
	transform: translateX(-60px);
}

.zc-progress li strong {
	display: block;
	font-weight: normal;
	font-size: 10.5pt;
	margin-bottom: 5px;
}

.zc-progress li small {
	display: block;
	line-height: 1;
}
</style>
