<template>
	<div class="zc-pagination" :class="{ 'zc-pagination-left': align == 'left', 'zc-pagination-right': align == 'right' }">
		<ul>
			<li @click="onGoToPrevious()" :class="{ disabled: current == 1 }">上一页</li>
			<template v-if="!tiny">
				<template v-for="(item, i) in sections">
					<template v-if="typeof(item) == 'number'">
						<li :class="{ current: item == current }" @click="onGoToIndex(item)" :key="i">{{item}}</li>
					</template>
					<template v-else-if="item == 'go.previous.7'">
						<li class="zc-pagination-step-previous" :class="{ current: item == current }" @click="onGoToSteps(-7)" :key="i">…</li>
					</template>
					<template v-else-if="item == 'go.next.7'">
						<li class="zc-pagination-step-next" :class="{ current: item == current }" @click="onGoToSteps(7)" :key="i">…</li>
					</template>
				</template>
			</template>
			<li @click="onGoToNext()" :class="{ disabled: current == pages.length }">下一页</li>
		</ul>
	</div>
</template>

<script>

export default {
	name: 'commonPagination',
	props: {
		total:
		{
			type: Number,
			default: 0,
			required: true
		},
		size:
		{
			type: Number,
			default: 10
		},
		index:
		{
			type: Number,
			default: 1
		},
		load:
		{
			type: Function
		},
		align: {
			type: String
		},
		tiny: {
			type: Boolean,
			default: false
		}
	},
	data()
	{
		return {
			current: 1
		}
	},
	computed: {
		pages()
		{
			return new Array(Math.ceil(this.total / this.size));
		},
		sections()
		{
			let sections = [];

			if (this.pages.length > 7)
			{
				let offsetLeft = this.current - 5,
					offsetRight = this.pages.length - this.current - 5 + 1;

				if (offsetLeft >= 0)
				{
					sections.push(1);
				}
				else
				{
					for (let i = 1; i <= 6; i++)
					{
						sections.push(i);
					}
					sections.push('go.next.7');
				}

				if (offsetLeft >= 0 && offsetRight >= 0)
				{
					sections.push('go.previous.7');
					for (let i = -2; i <= 2; i++)
					{
						sections.push(this.current + i);
					}
					sections.push('go.next.7');
				}

				if (offsetRight >= 0)
				{
					sections.push(this.pages.length);
				}
				else
				{
					sections.push('go.previous.7');
					for (let i = 5; i >= 0; i--)
					{
						sections.push(this.pages.length - i);
					}
				}
			}
			else
			{
				for (let i = 1; i < this.pages.length + 1; i++)
				{
					sections.push(i);
				}
			}

			return sections;
		}
	},
	watch:
	{
		index: {
			handler(n, o)
			{
				n && n > 0 ? this.current = n : this.current = 1;
			},
			immediate: true
		},
		current(n)
		{
			this.load && typeof (this.load) === 'function' && this.load(this.current, this.size);
		}
	},
	created()
	{
	},
	mounted()
	{
	},
	methods:
	{
		onGoToPrevious()
		{
			this.current - 1 >= 1 ? this.current-- : this.current = 1;
		},
		onGoToNext()
		{
			this.current + 1 < this.pages.length ? this.current++ : this.current = this.pages.length;
		},
		onGoToIndex(index)
		{
			this.current = index;
		},
		onGoToSteps(step)
		{
			let target = this.current + step;

			if (target < 1)
			{
				this.current = 1;
			}
			else if (target > this.pages.length)
			{
				this.current = this.pages.length;
			}
			else
			{
				this.current = target;
			}
		}
	}
}
</script>

<style scoped>
.zc-pagination {
	display: flex;
	user-select: none;
	align-content: center;
	justify-content: center;
	margin: 10px 0;
}

.zc-pagination.zc-pagination-left {
	justify-content: flex-start;
}

.zc-pagination.zc-pagination-right {
	justify-content: flex-end;
}
.zc-pagination ul {
	list-style: none;
	display: flex;
	background-color: #FFF;
	border-radius: 5px;
	overflow: hidden;
}
.zc-pagination li {
	display: block;
	min-width: 35px;
	height: 35px;
	box-sizing: border-box;
	padding: 0 8px;
	/* justify-content: center;
	align-items: center; */
	text-align: center;
	line-height: 35px;
	border-style: solid;
	border-color: #eee;
	border-width: 1px 0 1px 1px;
	cursor: pointer;
	overflow: hidden;
}

.zc-pagination li:first-child {
	border-radius: 5px 0 0 5px;
}

.zc-pagination li:last-child {
	border-radius: 0 5px 5px 0;
}

.zc-pagination li:last-child {
	border-right: none;
	border-right: solid 1px #eee;
}

.zc-pagination li:hover {
	background-color: #fa5302;
	color: #fff;
}

.zc-pagination li.current {
	background-color: #fa5302;
	color: #fff;
}

.zc-pagination li.disabled {
	background-color: #f9f9f9;
	color: #ccc;
	cursor: not-allowed;
	pointer-events: none;
}

.zc-pagination-step-previous,
.zc-pagination-step-next {
	font-size: 9pt;
}

.zc-pagination-step-previous:before,
.zc-pagination-step-next:before {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 18pt;
	line-height: 1.25em;
	font-weight: 100;
}

.zc-pagination-step-previous:hover:before {
	content: '«';
}

.zc-pagination-step-next:hover:before {
	content: '»';
}
</style>
