<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-25 14:38:04
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div>
		<mainHeader />
		<div class="py-container">
			<userMenu />
			<div class="yui3-u-5-6 zc-main">
				<div class="zc-panel">
					<template v-if="orders && orders.length">
						<table class="zc-table-title">
							<colgroup>
								<col style="width:auto;" />
								<col style="width:80px;" />
								<col style="width:80px" />
								<col style="width:100px" />
								<col style="width:120px" />
								<col style="width:120px" />
								<col style="width:140px" />
							</colgroup>
							<thead>
								<tr>
									<th>宝贝</th>
									<th>单价</th>
									<th>数量</th>
									<th>商品操作</th>
									<th>实付款</th>
									<th>交易状态</th>
									<th>订单操作</th>
								</tr>
							</thead>
						</table>
						<template v-for="(order, i) in orders">
							<div class="zc-table-item" :key="i">
								<div class="zc-table-spliter">
									<span>订单编号：{{order.orderSn}}</span>
									<span>{{order.addTime}}</span>
								</div>
								<table class="zc-table-content">
									<colgroup>
										<col style="width:auto;" />
										<col style="width:80px;" />
										<col style="width:80px" />
										<col style="width:100px" />
										<col style="width:120px" />
										<col style="width:120px" />
										<col style="width:140px" />
									</colgroup>
									<tbody>
										<tr v-for="(goods, m) in order.goodsList" :key="m" :temp="span = order.goodsList.length" :class="{ 'zc-tr-disabled': !goods.isExist }">
											<template>
												<td>
													<div class="zc-figure-text">
														<div class="zc-figure-image">
															<img :src="goods.picUrl" />
														</div>
														<h3>
															<strong>
																<a @click="$router.push({ path: `/product/detail/${goods.goodsId}` })" v-if="goods.isExist">{{goods.goodsName}}</a>
																<span v-else>{{goods.goodsName}}</span>
															</strong>
															<small>规格：{{goods.specifications.join(' ')}}</small>
														</h3>
													</div>
												</td>
												<td>
													<ul>
														<!-- <li class="zc-text-delete">¥{{goods.price}}</li> -->
														<li>¥{{goods.price}}</li>
													</ul>
												</td>
												<td>x{{goods.number}}</td>
												<td>
													<a style="margin:5px 0" class="button-solid button-yellow" v-if="order.handleOption.rebuy && goods.isExist" @click="onOrderAgain(goods.goodsId)">再次购买</a>
													<a style="margin:5px 0" class="button-solid button-yellow" v-if="goods.refundId" @click="onRefundDetail(goods.refundId)">{{goods.refundStatus == 2 ? '审批拒绝' : '退货查看'}}</a>
													<span v-if="!(order.handleOption.rebuy && goods.isExist) && !goods.refundId">-</span>
												</td>
											</template>
											<template v-if="m == 0">
												<td :rowspan="span">
													<ul>
														<li>¥{{order.actualPrice}}</li>
														<li>（含运费：￥{{order.freightPrice}}）</li>
													</ul>
												</td>
												<td :rowspan="span">
													<ul>
														<li>{{ order.orderStatusText }}</li>
														<li><a @click="$router.push({ path: `/user/order/detail/${order.id}` })">订单详情</a></li>
													</ul>
												</td>
												<td :rowspan="span" class="zc-table-content-operate">
													<a class="button-solid" @click="onOrderCancel(order)" v-if="order.handleOption.cancel">取消</a>
													<a class="button-solid" @click="onOrderDelete(order)" v-if="order.handleOption.delete">删除</a>
													<a class="button-block" @click="onOrderPay(order)" v-if="order.handleOption.pay">付款</a>
													<a class="button-block" @click="onOrderConfirm(order)" v-if="order.handleOption.confirm">收货</a>
													<a class="button-block" @click="$router.push({ path: `/user/order/refund/${order.id}` })" v-if="!['未付款', '已退款', '订单取消，退款中', '已取消', '已取消(系统)'].includes(order.orderStatusText)">退款</a>
												</td>
											</template>
										</tr>
									</tbody>
								</table>
							</div>
						</template>
						<commonPagination :total="query.total" :size="query.size" :load="getOrderList" v-if="query.total > query.size"></commonPagination>
					</template>
					<p class="zc-panel-empty" v-else>
						<img :src="require('@/assets/images/n2.png')" />
						<strong>暂无该类订单</strong>
						<small><a @click="$router.push({ path: '/home' })">继续逛逛吧</a></small>
					</p>
				</div>
				<div class="zc-panel" v-if="hots && hots.length">
					<div class="zc-panel-header">
						<h3 class="zc-panel-header-title">为您推荐</h3>
					</div>
					<div class="zc-panel-container zc-panel-container-row">
						<template v-for="(item, index) in hots">
							<productCard class="yui3-u-1-4" :url="`/product/detail/${item.id}`" :thumb="item.picUrl" :title="item.name" :price="item.retailPrice" :key="index" />
						</template>
					</div>
				</div>
			</div>
		</div>
		<mainFooter />

		<el-dialog title="退款详情" :visible.sync="dialog.refund" :close-on-click-modal="false" :close-on-press-escape="false" width="400px" class="zc-dialog  zc-dialog-detail">
			<el-form label-position="left" label-width="100px" size="mini">
				<el-form-item label="退单编号">
					<span>{{ form.refund.refundSn }}</span>
				</el-form-item>
				<el-form-item label="申请时间">
					<span>{{ form.refund.addTime }}</span>
				</el-form-item>
				<el-form-item label="退款状态">
					<span>{{ form.refund.status == 0 ? "等待审批" : form.refund.status == 2 ? "审批拒绝" : "审批通过" }}</span>
				</el-form-item>
				<el-form-item label="拒绝原因" v-if="form.refund.status == 2">
					<span>{{ form.refund.approveMsg }}</span>
				</el-form-item>
				<el-form-item label="申请总额">
					<span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
					<span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
				</el-form-item>
				<el-form-item label="审批总额" v-if="form.refund.status == 1 || form.refund.status == 3">
					<span v-if="form.refund.refundApplyAmount" style="margin-right: 10px;">{{ form.refund.refundApplyAmount }}元</span>
					<span v-if="form.refund.refundApplyIntegral">{{ form.refund.refundApplyIntegral }}积分</span>
				</el-form-item>
				<el-form-item label="原订单编号">
					<span>{{ form.refund.orderSn }}</span>
				</el-form-item>
				<el-form-item label="收货人">
					<span>{{ form.refund.consignee }}</span>
				</el-form-item>
				<el-form-item label="联系方式">
					<span>{{ form.refund.mobile }}</span>
				</el-form-item>
			</el-form>
			<img style="position:absolute;right:10px;bottom:30px;" :src="form.refund.status == 0 ? require('../../../assets/images/wait.png') : form.refund.status == 2 ? require('../../../assets/images/reject.png') : require('../../../assets/images/pass.png')" />
		</el-dialog>
	</div>
</template>

<script>
import mainHeader from '@/pc/components/header';
import mainFooter from '@/pc/components/footer';
import commonPagination from '@/pc/components/pagination';
import userMenu from '@/pc/components/userMenu';
import productCard from '@/pc/components/productCard';

export default {
	name: 'userOrder',
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		userMenu: userMenu,
		productCard: productCard,
		commonPagination: commonPagination
	},
	data()
	{
		let showTypes = {
			all: 0,
			pay: 1,
			send: 2,
			receive: 3,
			service: 4,
			complete: 5
		};

		return {
			showTypes: showTypes,
			query: {
				showType: showTypes[ this.$route.params.mode ],
				size: 5,
				page: 1,
				total: 0
			},
			orders: [],
			dialog: {
				refund: false
			},
			form: {
				refund: {}
			},
			hots: []
		}
	},
	computed: {
		user()
		{
			return this.$store.getters.user
		},
		isLogin()
		{
			return this.$store.getters.isLogin
		},
		dynamicOrderRefund()
		{
			return this.$store.getters.dynamicOrderRefund
		},
	},
	watch:
	{
		'$route.params.mode':
		{
			handler(n, o)
			{
				if (n)
				{
					this.query.showType = this.showTypes[ this.$route.params.mode ];
					this.getOrderList(1, 10);
				}
			},
			immediate: true
		}
	},
	created()
	{
		this.getHotList();
	},
	mounted()
	{
		if (this.dynamicOrderRefund)
		{
			this.onRefundDetail(this.dynamicOrderRefund)
		}
	},
	methods:
	{
		getHotList()
		{
			this.$api.base.HotGoods({ size: 4 }).then(response =>
			{
				this.hots = response.hotGoodsList.map(item =>
				{
					item.goods.retailPrice = Math.min(...item.products.map(i => i.price));
					return item.goods;
				});
			});
		},
		getOrderList(page, size)
		{
			if (page != undefined)
			{
				this.query.page = page;
			}

			if (size != undefined)
			{
				this.query.size = size;
			}

			this.$api.base.OrderList(Object.assign({ ...this.query }, { page, size })).then(response =>
			{
				this.orders = response.data;
				this.query.total = response.totalCount;
			});
		},
		onOrderPay(order)
		{
			this.$router.push({
				path: '/order/pay',
				query: {
					orderId: order.id,
					actualPrice: order.actualPrice,
					orderSn: order.orderSn
				}
			});
		},
		onOrderCancel(order)
		{
			this.$api.base.OrderCancel({ orderId: order.id }).then(response =>
			{
				this.getOrderList();
			});
		},
		onOrderDelete(order)
		{
			this.$api.base.OrderDelete({ orderId: order.id }).then(response =>
			{
				this.getOrderList();
			});
		},
		onOrderConfirm(order)
		{
			this.$api.base.OrderConfirm({ orderId: order.id }).then(response =>
			{
				this.getOrderList();
			});
		},
		onOrderAgain(id)
		{
			this.$router.push({ path: '/product/detail/' + id });
		},
		onRefundDetail(id)
		{
			this.$api.base.RefundOrderQuery({ refundId: id }).then(response =>
			{
				this.form.refund = Object.assign(response.refundTrace, { consignee: response.orderInfo.consignee, mobile: response.orderInfo.mobile });
				this.dialog.refund = true;
			});
		}
	}
}
</script>

<style scoped>
</style>
