import router from "../../pc/router";
import QRCode from "./qrcode.min.js";
import ws from "./ws";

export default function(Vue) {
  // 兼容移动端 uniapp
  Vue.prototype.setData = function(obj) {
    let that = this;
    let keys = [];
    let val, data;
    Object.keys(obj).forEach(function(key) {
      keys = key.split(".");
      val = obj[key];
      data = that.$data;
      keys.forEach(function(key2, index) {
        if (index + 1 == keys.length) {
          that.$set(data, key2, val);
        } else {
          if (!data[key2]) {
            that.$set(data, key2, {});
          }
        }
        data = data[key2];
      });
    });
  };

  function create(Component, props) {
    const Ctor = Vue.extend(Component);
    const comp = new Ctor({ propsData: props });
    comp.$mount();
    document.body.appendChild(comp.$el);
    comp.remove = () => {
      document.body.removeChild(comp.$el);
      comp.$destroy();
    };
    return comp;
  }

  const ToastObj = {
    props: ["title", "image", "duration"],
    render: function render() {
      var _vm = this;
      var _h = _vm.$createElement;
      var _c = _vm._self._c || _h;
      return _c("div", [
        _c("style", {
          domProps: {
            innerHTML: `
              .popup-wrap1 {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 8px;
                z-index: 600;
                background: rgba(0, 0, 0, 0.6);
                padding: 20px 30px;
                color: rgb(255, 255, 255);
                font-size: 16px;
                line-height: 24px;
                white-space: pre-wrap;
                word-break: break-all;
                text-align: center;
                max-width: 300px;
              }
              .popup-img {
                margin: 10px auto;
              }
            `,
          },
        }),
        _c(
          "div",
          {
            staticClass: "popup-wrap1",
          },
          [
            _vm.image
              ? _c("img", {
                  staticClass: "popup-img",
                  attrs: {
                    src: "image",
                    alt: "",
                  },
                })
              : _vm._e(),
            _c("div", [_vm._v(_vm._s(_vm.title))]),
          ]
        ),
      ]);
    },
    mounted() {
      this.show();
    },
    methods: {
      show() {
        setTimeout(() => {
          this.remove();
        }, this.duration);
      },
    },
  };

  const ModalObj = {
    props: {
      title: {
        type: String,
        default: "",
      },
      content: {
        type: String,
        default: "",
      },
      success: {
        type: Function,
        default: () => {},
      },
      fail: {
        type: Function,
        default: () => {},
      },
      showCancel: {
        type: Boolean,
        default: false,
      },
      show: {
        type: Boolean,
        default: true,
      },
      confirmText: {
        type: String,
        default: "确 定",
      },
      cancelText: {
        type: String,
        default: "取 消",
      },
    },
    render: function render() {
      var _vm = this;
      var _h = _vm.$createElement;
      var _c = _vm._self._c || _h;
      return _vm.show
        ? _c("div", [
            _c("style", {
              domProps: {
                innerHTML: `
                .popup-wrap {
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                  z-index: 1000;
                  background: rgba(0, 0, 0, 0.5);
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .popup-box {
                  max-width: 800px;
                  background: #fff;
                  border-radius: 8px;
                  min-width: 500px;
                }
                .popup-title {
                  font-size: 18px;
                  font-weight: 700;
                  padding: 20px;
                  border-bottom: 1px solid #d7d7d7;
                }
                .popup-content {
                  padding: 20px 20px 0;
                  font-size: 16px;
                  max-height: 600px;
                  overflow: hidden auto;
                }
                .popup-footer {
                  padding: 20px;
                  display: flex;
                  justify-content: flex-end;
                }
                .popup-btn {
                  color: #fff;
                  outline: none;
                  border: 0;
                  background: #409eff;
                  font-size: 14px;
                  border-radius: 4px;
                  padding: 8px 20px;
                  margin-left: 20px;
                }
              `,
              },
            }),
            _c(
              "div",
              {
                staticClass: "popup-wrap",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup-box",
                  },
                  [
                    _vm.title
                      ? _c(
                          "div",
                          {
                            staticClass: "popup-title",
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "popup-content",
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.content),
                          },
                        }),
                        _vm._t("default"),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "popup-footer",
                      },
                      [
                        _vm.showCancel
                          ? _c(
                              "button",
                              {
                                staticClass: "popup-btn",
                                on: {
                                  click: _vm.popupBtn1,
                                },
                              },
                              [_vm._v(_vm._s(_vm.cancelText))]
                            )
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "popup-btn",
                            on: {
                              click: _vm.popupBtn2,
                            },
                          },
                          [_vm._v(_vm._s(_vm.confirmText))]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e();
    },
    mounted() {},
    methods: {
      popupBtn1() {
        this.fail &&
          this.fail({
            cancel: true,
          });
        if (this.remove) {
          this.remove();
        } else {
          this.$emit("update:show", false);
          this.$emit("fail", {
            cancel: true,
          });
        }
      },
      popupBtn2() {
        this.success &&
          this.success({
            confirm: true,
          });
        if (this.remove) {
          this.remove();
        } else {
          this.$emit("update:show", false);
          this.$emit("success", {
            confirm: true,
          });
        }
      },
    },
  };

  Vue.prototype.$showToast = (obj) => {
    let temp = {
      title: obj.title || "",
      image: "",
      duration: obj.duration || 2000,
    };
    return create(ToastObj, temp);
  };

  Vue.prototype.$showModal = (obj) => {
    let temp = {
      title: obj.title,
      content: obj.content,
      success: obj.success,
      fail: obj.fail,
      showCancel: obj.showCancel,
      confirmText: obj.confirmText,
      cancelText: obj.cancelText,
    };
    return create(ModalObj, temp);
  };

  Vue.component("Dialog", ModalObj);

  window.uni = {
    showToast: function(obj) {
      return Vue.prototype.$showToast(obj);
    },
    showModal: function(obj) {
      return Vue.prototype.$showModal(obj);
    },
    setStorageSync: function(key, value) {
      let temp = null;
      if (value !== null && value !== undefined) {
        temp = JSON.stringify(value);
        localStorage.setItem(key, temp);
      }
      return temp;
    },
    getStorageSync: function(key) {
      let temp = null;
      try {
        temp = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
      } catch (error) {}
      return temp;
    },
    navigateTo: function(obj) {
      router.push({
        path: obj.url,
      });
    },
    setNavigationBarTitle: function(obj) {
      document.title = obj.title;
    },
    upx2px: function(num) {
      return num * (window.innerWidth / 750);
    },
    utils: {
      urlQuery: function(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
          return unescape(r[2]);
        }
        return null;
      },
      qsStringify: function(data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret.slice(0, -1);
      },
      StrQuery: function(urlStr, key) {
        if (urlStr) {
          let obj = {};
          let strSplit = urlStr.split("#")[0];
          let arr1 = strSplit.split(/[&?]/g);
          if (arr1.length) {
            for (let i = 0; i < arr1.length; i++) {
              let index1 = arr1[i].indexOf("=");
              let keyStr = arr1[i].substr(0, index1);
              if (keyStr) {
                let valueStr = arr1[i].substr(index1 + 1);
                obj[keyStr] = decodeURIComponent(valueStr);
              }
            }
          }
          if (key) {
            return obj[key];
          }
          return obj;
        }
      },
      clipboard: {
        set: (txt) => {
          if (document.queryCommandSupported("copy")) {
            let textarea = document.createElement("textarea");
            textarea.value = txt;
            textarea.readOnly = "readOnly";
            document.body.appendChild(textarea);
            textarea.select();
            textarea.setSelectionRange(0, txt.length);
            document.execCommand("copy");
            textarea.remove ? textarea.remove() : textarea.removeNode(true);
          }
        },
        get: () => {
          if (navigator.clipboard) {
            return navigator.clipboard.readText();
            // navigator.clipboard.writeText(content);
            // window.clipboardData.getData("Text");
            // window.clipboardData.setData("Text", content);
          }
        },
      },
      tiemFmt: function(str, fmt) {
        const date = new Date(str);
        var o = {
          "y+": date.getFullYear(),
          "Y+": date.getFullYear(),
          "M+": date.getMonth() + 1, // 月份
          "d+": date.getDate(), // 日
          "D+": date.getDate(), // 日
          "H+": date.getHours(), // 小时
          "m+": date.getMinutes(), // 分
          "s+": date.getSeconds(), // 秒
          "S+": date.getMilliseconds(), // 毫秒
          W: date.getDay(), // 星期
          q: Math.floor((date.getMonth() + 3) / 3), // 季度
        };
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, ("0000" + o[k]).substr(-RegExp.$1.length));
        }
        return fmt;
      },
    },
    QRCode: QRCode,
    ws: ws,
  };
}
