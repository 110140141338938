<template>
	<div>
		<div id="floor-index" class="floor-index">
			<ul>
				<li>
					<a class="num" href="javascript:;" style="display: none;">1F</a>
					<a class="word" href="javascript;;" style="display: block;">家用电器</a>
				</li>
				<li>
					<a class="num" href="javascript:;" style="display: none;">2F</a>
					<a class="word" href="javascript;;" style="display: block;">手机通讯</a>
				</li>
				<li>
					<a class="num" href="javascript:;" style="display: none;">3F</a>
					<a class="word" href="javascript;;" style="display: block;">电脑办公</a>
				</li>
				<li>
					<a class="num" href="javascript:;" style="display: none;">4F</a>
					<a class="word" href="javascript;;" style="display: block;">家居家具</a>
				</li>
				<li>
					<a class="num" href="javascript:;" style="display: none;">5F</a>
					<a class="word" href="javascript;;" style="display: block;">运动户外</a>
				</li>
			</ul>
		</div>
		<div class="J-global-toolbar">
			<div class="toolbar-wrap J-wrap">
				<div class="toolbar">
					<div class="toolbar-panels J-panel">

						<!-- 购物车 -->
						<div style="visibility: hidden;" class="J-content toolbar-panel tbar-panel-cart toolbar-animate-out">
							<h3 class="tbar-panel-header J-panel-header">
								<a href="" class="title">
									<i></i>
									<em class="title">购物车</em>
								</a>
								<span class="close-panel J-close" onclick="cartPanelView.tbar_panel_close('cart');"></span>
							</h3>
							<div class="tbar-panel-main">
								<div class="tbar-panel-content J-panel-content">
									<div id="J-cart-tips" class="tbar-tipbox hide">
										<div class="tip-inner">
											<span class="tip-text">还没有登录，登录后商品将被保存</span>
											<a href="#none" class="tip-btn J-login">登录</a>
										</div>
									</div>
									<div id="J-cart-render">
										<!-- 列表 -->
										<div id="cart-list" class="tbar-cart-list">
										</div>
									</div>
								</div>
							</div>
							<!-- 小计 -->
							<div id="cart-footer" class="tbar-panel-footer J-panel-footer">
								<div class="tbar-checkout">
									<div class="jtc-number">
										<strong class="J-count" id="cart-number">0</strong>件商品
									</div>
									<div class="jtc-sum"> 共计：
										<strong class="J-total" id="cart-sum">¥0</strong>
									</div>
									<a class="jtc-btn J-btn" href="#none" target="_blank">去购物车结算</a>
								</div>
							</div>
						</div>

						<!-- 我的关注 -->
						<div style="visibility: hidden;" data-name="follow" class="J-content toolbar-panel tbar-panel-follow">
							<h3 class="tbar-panel-header J-panel-header">
								<a href="#" target="_blank" class="title">
									<i></i>
									<em class="title">我的关注</em>
								</a>
								<span class="close-panel J-close" onclick="cartPanelView.tbar_panel_close('follow');"></span>
							</h3>
							<div class="tbar-panel-main">
								<div class="tbar-panel-content J-panel-content">
									<div class="tbar-tipbox2">
										<div class="tip-inner">
											<i class="i-loading"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="tbar-panel-footer J-panel-footer"></div>
						</div>

						<!-- 我的足迹 -->
						<div style="visibility: hidden;" class="J-content toolbar-panel tbar-panel-history toolbar-animate-in">
							<h3 class="tbar-panel-header J-panel-header">
								<a href="#" target="_blank" class="title">
									<i></i>
									<em class="title">我的足迹</em>
								</a>
								<span class="close-panel J-close" onclick="cartPanelView.tbar_panel_close('history');"></span>
							</h3>
							<div class="tbar-panel-main">
								<div class="tbar-panel-content J-panel-content">
									<div class="jt-history-wrap">
										<ul>
											<!--<li class="jth-item">
										<a href="#" class="img-wrap"> <img src="../../.../portal/img/like_03.png" height="100" width="100" /> </a>
										<a class="add-cart-button" href="#" target="_blank">加入购物车</a>
										<a href="#" target="_blank" class="price">￥498.00</a>
									</li>
									<li class="jth-item">
										<a href="#" class="img-wrap"> <img src="../../../portal/img/like_02.png" height="100" width="100" /></a>
										<a class="add-cart-button" href="#" target="_blank">加入购物车</a>
										<a href="#" target="_blank" class="price">￥498.00</a>
									</li>-->
										</ul>
										<a href="#" class="history-bottom-more" target="_blank">查看更多足迹商品 &gt;&gt;</a>
									</div>
								</div>
							</div>
							<div class="tbar-panel-footer J-panel-footer"></div>
						</div>

					</div>

					<div class="toolbar-header"></div>

					<!-- 侧栏按钮 -->
					<div class="toolbar-tabs J-tab">
						<div onclick="cartPanelView.tabItemClick('cart')" class="toolbar-tab tbar-tab-cart" data="购物车" tag="cart">
							<i class="tab-ico"></i>
							<em class="tab-text"></em>
							<span class="tab-sub J-count " id="tab-sub-cart-count">0</span>
						</div>
						<div onclick="cartPanelView.tabItemClick('follow')" class="toolbar-tab tbar-tab-follow" data="我的关注" tag="follow">
							<i class="tab-ico"></i>
							<em class="tab-text"></em>
							<span class="tab-sub J-count hide">0</span>
						</div>
						<div onclick="cartPanelView.tabItemClick('history')" class="toolbar-tab tbar-tab-history" data="我的足迹" tag="history">
							<i class="tab-ico"></i>
							<em class="tab-text"></em>
							<span class="tab-sub J-count hide">0</span>
						</div>
					</div>

					<div class="toolbar-footer">
						<div class="toolbar-tab tbar-tab-top">
							<a href="#">
								<i class="tab-ico  "></i>
								<em class="footer-tab-text">顶部</em>
							</a>
						</div>
						<div class="toolbar-tab tbar-tab-feedback">
							<a href="#" target="_blank">
								<i class="tab-ico"></i>
								<em class="footer-tab-text ">反馈</em>
							</a>
						</div>
					</div>

					<div class="toolbar-mini"></div>

				</div>

				<div id="J-toolbar-load-hook"></div>

			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'commonDock',
	props: {
		complex:
		{
			type: Boolean,
			default: false,
			required: false
		}
	},
	data()
	{
		return {
		}
	},
	computed: {
		user()
		{
			return this.$store.getters.user
		},
		isLogin()
		{
			return this.$store.getters.isLogin
		}
	},
	watch:
	{
	},
	created()
	{
	},
	mounted()
	{
	},
	methods:
	{
	}
}
</script>

<style scoped>
</style>
