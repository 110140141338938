<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-28 16:57:37
 * @LastEditors: xunmi muer3mu@126.com
 * @LastEditTime: 2022-08-29 15:28:04
 * @FilePath: \SuperMalld:\Projects\pc自建商城\client\src\pc\pages\user\cart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div>
		<mainHeader />
		<div class="py-container">
			<userMenu />
			<div class="yui3-u-5-6 zc-main">
				<div class="zc-panel">
					<div class="zc-panel-header">
						<h3 class="zc-panel-header-title">收藏的商品</h3>
						<div class="zc-panel-header-operate" v-if="collectList && collectList.length">
							<template v-if="isBat">
								<a @click="onAllSelect">全选</a>
								<a @click="onDeleteFavoriteBat">删除</a>
								<a @click="onBat">取消批量</a>
							</template>
							<a @click="onBat" v-else>批量处理</a>
						</div>
					</div>
					<div class="zc-panel-container zc-panel-container-row">
						<template v-if="collectList && collectList.length">
							<template v-for="(product, index) in collectList">
								<productCard class="yui3-u-1-5 collect-item" :url="`/product/detail/${product.valueId}`" :thumb="product.picUrl" :title="product.name" :price="product.retailPrice" :disable="!product.isExist ? '商品失效' : false" :key="index">
									<a class="card-delete" @click.stop="onDeleteFavorite(product.valueId)" v-if="!isBat"></a>
									<div class="card-delete-check" v-else @click.stop="onToggleSelect()">
										<el-checkbox v-model="product.checked" />
									</div>
								</productCard>
							</template>
						</template>
						<p class="zc-panel-empty" v-else>
							<img :src="require('@/assets/images/n2.png')" />
							<strong>您还没有收藏</strong>
							<small><a @click="$router.push({ path: '/home' })">继续逛逛吧</a></small>
						</p>
					</div>
					<div class="zc-panel-footer" v-if="collectList && collectList.length">
						<commonPagination :total="totalCount" :load="getCollectList"></commonPagination>
					</div>
				</div>

				<div class="zc-panel" v-if="hots && hots.length">
					<div class="zc-panel-header">
						<h3 class="zc-panel-header-title">为您推荐</h3>
					</div>
					<div class="zc-panel-container zc-panel-container-row">
						<template v-for="(item, index) in hots">
							<productCard class="yui3-u-1-4" :url="`/product/detail/${item.id}`" :thumb="item.picUrl" :title="item.name" :price="item.retailPrice" :key="index" />
						</template>
					</div>
				</div>
			</div>
		</div>
		<mainFooter />
	</div>
</template>

<script>
import '@/assets/css/pages-seckillOrder.css';

import mainHeader from '@/pc/components/header';
import mainFooter from '@/pc/components/footer';
import commonPagination from '@/pc/components/pagination';
import userMenu from '@/pc/components/userMenu';
import productCard from '@/pc/components/productCard';

export default {
	name: 'userFavorite',
	components: {
		mainHeader: mainHeader,
		mainFooter: mainFooter,
		userMenu: userMenu,
		productCard: productCard,
		commonPagination: commonPagination
	},
	data()
	{
		return {
			type: 0,
			collectList: [],
			page: 1,
			size: 10,
			totalPages: 1,
			totalCount: 0,
			hots: [],
			isBat: false
		}
	},
	computed: {
		user()
		{
			return this.$store.getters.user
		},
		isLogin()
		{
			return this.$store.getters.isLogin
		}
	},
	watch:
	{
	},
	created()
	{
		this.getCollectList();
		this.getHotList();
	},
	mounted()
	{
	},
	methods:
	{
		getCollectList(page)
		{
			this.$api.base
				.CollectList({
					type: this.type,
					page: page,
					size: this.size
				})
				.then(res =>
				{
					res.collectList.forEach(item => item.checked = false);
					this.collectList = res.collectList;
					this.totalPages = res.totalPages;
					this.totalCount = res.totalCount;
				});
		},
		getHotList()
		{
			this.$api.base.HotGoods({ size: 4 }).then(response =>
			{
				this.hots = response.hotGoodsList.map(item =>
				{
					item.goods.retailPrice = Math.min(...item.products.map(i => i.price));
					return item.goods;
				});
			});
		},
		onBat()
		{
			this.isBat = !this.isBat;
		},
		onToggleSelect(item)
		{
		},
		onAllSelect()
		{
			this.collectList.forEach(item => item.checked = true);
		},
		onDeleteFavorite(id)
		{
			this.$api.base.CollectDeleteBat({ type: 0, valueId: id + '' }).then(response => this.getCollectList());
		},
		onDeleteFavoriteBat()
		{
			let ids = this.collectList.filter(item => item.checked).map(item => item.valueId);

			if (ids.length)
			{
				this.$api.base.CollectDeleteBat({ type: 0, valueId: ids.toString() }).then(response => this.getCollectList());
				this.getCollectList();
			}
			else
			{
				uni.showToast({ title: "请选择收藏的商品" });
			}
		}
	}
}
</script>

<style scoped>
.card-delete {
	background-image: url(../../../assets/images/delete.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
	background-color: rgba(0, 0, 0, 0.2);
	display: block;
	width: 25px;
	height: 25px;
	border-radius: 3px;
	position: absolute;
	right: 5px;
	top: 5px;
	opacity: 0;
	transform: scale(0.2);
	transition: all 0.25s cubic-bezier(0.57, -0.17, 0.64, 1.36);
}

.collect-item:hover .card-delete {
	opacity: 1;
	transform: scale(1);
}

.card-delete-check {
	display: block;
	position: absolute;
	right: 5px;
	top: 5px;
}
</style>
