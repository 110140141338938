/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:02
 * @LastEditTime: 2022-07-28 10:13:38
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 接口索引页
 * @FilePath: \SuperMall\src\common\api\index.js
 */

import base from './base';
import wechat from './wechat';

export default {
	base,
	wechat
};