<template>
  <div class="k-box">
    <div class="k-bigImg">
      <div class="k-left">
        <img class="k-leftImg" :src="list[smallStyleIndex]" alt="" />
        <div v-show="maskShow" class="k-leftMask" :style="leftStyle"></div>
      </div>
      <div v-show="maskShow" class="k-right">
        <img :style="rightStyle" class="k-rightImg" :src="list[smallStyleIndex]" alt="" />
      </div>
    </div>
    <div class="k-imgList">
      <div class="k-left" @click="leftBtnSmall">&lt;</div>
      <div class="k-item">
        <div class="k-itemWrap" :style="{ left: `-${this.smallStyleNum}px` }">
          <div class="k-listImg" :class="{ 'k-listImg-on': index === smallStyleIndex }" v-for="(item, index) in list" :key="index" @mouseenter="indexChange(index, item)">
            <img class="k-itemImg" :src="item" alt="" />
          </div>
        </div>
      </div>
      <div class="k-right" @click="rightBtnSmall">&gt;</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      smallStyleNum: 0,
      smallStyleIndex: 0,
      leftStyle: "",
      rightStyle: "",
      maskShow: false,
      moveXY: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      }
    };
  },
  mounted() {
    document.querySelector(".k-left").addEventListener("mouseenter", this.enterHandler);
    window.addEventListener("mousemove", this.moveHandler);
    document.querySelector(".k-left").addEventListener("mouseleave", this.outHandler);
  },
  destroyed() {
    window.removeEventListener("mousemove", this.moveHandler);
    if (document.querySelector(".k-left")) {
      document.querySelector(".k-left").removeEventListener("mouseenter", this.enterHandler);
      document.querySelector(".k-left").removeEventListener("mouseleave", this.outHandler);
    }
  },
  methods: {
    enterHandler() {
      this.maskShow = true;
      this.moveXY.x2 = document.querySelector(".k-left").getBoundingClientRect().left + 100;
      this.moveXY.y2 = document.querySelector(".k-left").getBoundingClientRect().top + 100;
    },
    moveHandler(ev) {
      if (this.maskShow) {
        this.moveXY.x1 = ev.pageX - this.moveXY.x2;
        this.moveXY.y1 = ev.pageY - this.moveXY.y2;
        if (this.moveXY.x1 < 0) {
          this.moveXY.x1 = 0;
        } else if (this.moveXY.x1 > 200) {
          this.moveXY.x1 = 200;
        }
        if (this.moveXY.y1 < 0) {
          this.moveXY.y1 = 0;
        } else if (this.moveXY.y1 > 200) {
          this.moveXY.y1 = 200;
        }
        this.leftStyle = `left: ${this.moveXY.x1}px;top: ${this.moveXY.y1}px`;
        this.rightStyle = `left: ${-2 * this.moveXY.x1}px;top: ${-2 * this.moveXY.y1}px`;
      }
    },
    outHandler() {
      this.maskShow = false;
    },
    leftBtnSmall() {
      this.smallStyleNum -= 150;
      if (this.smallStyleNum < 0) {
        this.smallStyleNum = 0;
      }
    },
    rightBtnSmall() {
      let rightpx = (this.list.length - 5) * 75;
      this.smallStyleNum += 150;
      if (this.smallStyleNum > rightpx) {
        this.smallStyleNum = rightpx;
      }
    },
    indexChange(index) {
      this.smallStyleIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.k-box {
  font-size: 0;
  user-select: none;
  .k-bigImg {
    position: relative;
    border: 1px solid #999;
    width: 400px;
    height: 400px;
    .k-left {
      cursor: pointer;
    }
    .k-leftImg {
      width: 400px;
      height: 400px;
    }
    .k-leftMask {
      width: 200px;
      height: 200px;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
    }
    .k-right {
      position: absolute;
      z-index: 50;
      top: 0;
      left: 420px;
      width: 400px;
      height: 400px;
      border: 1px solid #999;
      overflow: hidden;
    }
    .k-rightImg {
      width: 800px;
      height: 800px;
      max-width: 800px;
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
    }
  }
  .k-imgList {
    width: 400px;
    margin-top: 10px;
    display: flex;
    .k-item {
      width: 0;
      flex: 1;
      height: 55px;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
    }
    .k-itemWrap {
      position: absolute;
      z-index: 50;
      padding: 0 10px;
      left: 0;
      top: 0;
      transition: all 0.2s;
    }
    .k-itemImg {
      width: 51px;
      height: 51px;
    }
    .k-listImg {
      border: 2px solid #fff;
      margin-right: 20px;
      display: inline-block;
    }
    .k-listImg-on {
      border-color: #f60;
    }
    .k-left,
    .k-right {
      width: 12px;
      height: 52px;
      border-radius: 2px;
      border: 1px solid #ccc;
      background: #ebebeb;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
