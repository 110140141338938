<template>
  <div class="typeNav">
    <div class="py-container">
      <div class="yui3-g NavList">
        <div class="all-sorts-list" v-if="this.$route.name == 'homeIndex'">
          <div class="yui3-u Left all-sort">
            <h4>全部商品分类</h4>
          </div>
          <div class="sort" v-if="complex">
            <div class="all-sort-list2">
              <div class="item bo" v-for="(item, index) in categoryList" :key="index">
                <h3>
                  <a href="">{{ item.name }}</a>
                </h3>
                <div class="item-list clearfix">
                  <div class="subitem">
                    <dl class="fore" v-for="(item1, index1) in item.children" :key="index1">
                      <dt>
                        <a>{{ item1.name }}</a>
                      </dt>
                      <dd>
                        <em v-for="(item2, index2) in item1.children" :key="index2">
                          <a @click="$router.push('/product/catalog/' + item2.id)">{{ item2.name }}</a>
                        </em>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="yui3-u Center navArea">
          <ul class="nav">
            <li class="f-item" @click="navArea(1)">首页</li>
            <li class="f-item" @click="navArea(2)">每日特价</li>
            <li class="f-item" @click="navArea(3)">泽超心选</li>
            <li class="f-item" @click="navArea(4)">品牌闪购</li>
            <li class="f-item" @click="navArea(5)">合作商户</li>
          </ul>
        </div>
        <div class="yui3-u Right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonNavigator",
  props: {
    complex: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      categoryList: [],
      currentCategory: {},
      currentSubCategoryList: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.CatalogDatas();
  },
  methods: {
    navArea(i) {
      if (i === 1) {
        this.$router.push("/");
      }
      if (i === 2) {
        this.$router.push("/");
        this.$emit("navclick", 5);
        sessionStorage.navclick = "5";
      }
      if (i === 3) {
        this.$router.push("/");
        this.$emit("navclick", 3);
        sessionStorage.navclick = "3";
      }
      if (i === 4) {
        this.$router.push("/");
        this.$emit("navclick", 4);
        sessionStorage.navclick = "4";
      }
      if (i === 5) {
        uni.showToast({
          title: "正在开发中，敬请期待！"
        });
      }
    },
    CatalogDatas() {
      this.$api.base.CatalogDatas().then((res) => {
        this.categoryList = res;
        if (this.categoryList && this.categoryList.length) {
          this.currentCategory = this.categoryList[0];
          if (this.currentCategory && this.currentCategory.children && this.currentCategory.children[0]) {
            this.currentSubCategoryList = this.currentCategory.children[0].children || [];
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.all-sort-list2 {
  .item {
    &:hover {
      background: #f60;
      cursor: pointer;
    }
    &:hover .item-list {
      display: block;
    }
  }
}

.item-list {
      left: 220px !important;
}
.f-item {
  cursor: pointer;
}

.sort {
  border-radius:  0 0 8px 8px;
  width: 220px;
}

.all-sort {
  width: 220px;
}
</style>
