class ws {
  constructor(url) {
    this.url = url;
    this.ws = new WebSocket(this.url);
    this.promiseList = [];
    this.obj = {};
    this.msgBack = [];
  }

  init(obj) {
    if (obj) {
      this.obj = obj;
    }
    this.promiseList = [];
    this.ws.onopen = () => {
      this.wsOnopen();
    };
    this.ws.onmessage = (e) => {
      this.wsOnmessage(e);
    };
    this.ws.onclose = () => {
      this.wsOnclose();
    };
    this.ws.onerror = () => {
      this.wsOnerror();
    };
  }

  wsOnopen() {
    if (this.obj && this.obj.open) {
      this.obj.open();
    }
    console.log("WebSocket Open");
  }

  wsOnmessage(e) {
    if (this.obj && this.obj.message) {
      this.obj.message();
    }
    this.websocketonmessage(e);
  }

  wsOnclose() {
    if (this.obj && this.obj.close) {
      this.obj.close();
    }
    console.log("WebSocket Close");
  }

  wsOnerror() {
    if (this.obj && this.obj.error) {
      this.obj.error();
    }
    console.log("WebSocket Error");
  }

  close() {
    this.ws.close();
  }

  push(cb) {
    this.msgBack.push(cb);
  }

  post(type, payload = "") {
    return new Promise((resolve, reject) => {
      let send = {
        payload,
        type
      };
      this.send(send)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //数据发送
  websocketsend(msg, resolve, reject) {
    const send = {
      type: msg.type,
      resolve: resolve,
      reject: reject
    };
    this.ws.send(JSON.stringify(msg));
    if (msg.type) {
      this.promiseList.push(send);
    }
  }

  //数据接收
  websocketonmessage(e) {
    let data = e.data;
    try {
      data = JSON.parse(data);
    } catch (error) {}

    this.msgBack.forEach((v1) => {
      v1 && v1(data);
    });
    this.promiseList.forEach((item, index, arr) => {
      if (data && data.type && data.type === item.type) {
        if (data.payload) {
          item.resolve(data);
        } else {
          item.reject(data);
        }
        arr.splice(index, 1);
      }
    });
  }

  // 实际调用的方法
  send(agentData) {
    const sPromise = new Promise((resolve, reject) => {
      if (this.ws.readyState === this.ws.OPEN) {
        //若是ws开启状态
        this.websocketsend(agentData, resolve, reject);
      } else if (this.ws.readyState === this.ws.CONNECTING) {
        // 若是 正在开启状态，则等待后重新调用
        setTimeout(() => {
          this.websocketsend(agentData, resolve, reject);
        }, 1000);
      } else {
        this.init();
        reject();
      }
    });
    return sPromise;
  }
}

export default ws;
